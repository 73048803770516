import React, { useState, useEffect, useContext } from 'react';
import { Grid, Typography, Container, Button, Box } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { AuthContext } from '../../shared/context/auth-context';

import SubList from '../components/SubList';

const useStyles = makeStyles(theme => ({
  mainContainer: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3)
  }
}));

const UserPanel = () => {
  const classes = useStyles();
  const [userSubscriptions, setUserSubscriptions] = useState(null);
  const auth = useContext(AuthContext);

  const handleUpdateData = () => {
    fetch(process.env.REACT_APP_API_URL+'/api/users/subscriptions/'+auth.userId, {
      headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer '+auth.token }
    })
      .then(result => result.json())
      .then(data => setUserSubscriptions(data))
  }

  useEffect(() => {
    let isSubscribed = true;
  
    fetch(process.env.REACT_APP_API_URL+'/api/users/subscriptions/'+auth.userId, {
      headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer '+auth.token }
    })
      .then(result => result.json())
      .then(data => (isSubscribed ? setUserSubscriptions(data) : null))
    
    return () => (isSubscribed = false);
  }, [auth.userId, auth.token])

  return(
    <Container className={classes.mainContainer}>
      <Grid
        container         
        direction="row"
        justify="center"
        alignItems="center"
      >
          <Grid item xs={12}>
            <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="center"
              spacing={2}
            >
              <Grid item xs={12} md={8}>
                <Typography variant="h4" gutterBottom>Mis Subscripciones</Typography>
              </Grid>
              <Grid item xs={12} md={2}>
                <Button
                  component={RouterLink}
                  to="/logout"
                  variant="contained"
                  fullWidth={true}
                >
                  Cerrar sesión
                </Button>
              </Grid>
            </Grid>

          </Grid>
        
        <Grid item xs={12}>
          <Box mt={3}>
            <SubList 
              userSubscriptions={userSubscriptions}
              onUpdateData={handleUpdateData}
              location="user_panel"
            ></SubList>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
}

export default UserPanel;