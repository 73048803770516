import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Stepper, Step, StepLabel, Typography, Grid, Paper, Container, Button, Box } from '@material-ui/core';
import { AuthContext } from '../../shared/context/auth-context';

import AddressForm from '../components/AddressForm';
import AddressList from '../components/AddressList';
import CardList from '../components/CardList';

const useStyles = makeStyles(theme => ({
  mainPaper: {
    width: '100%',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3)
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(3),
  },
}));

function getSteps () {
  return ['Información de Despacho', 'Medio de pago'];
}

const Subscription = () => {
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const [userAddresses, setUserAddresses] = useState(null);
  const [userCards, setUserCards] = useState(null);
  const [currentSubId, setCurrentSubId] = useState(null);
  const steps = getSteps();
  const auth = useContext(AuthContext);
  let history = useHistory();

  useEffect(() => {
    let isSubscribed = true;
  
    fetch(process.env.REACT_APP_API_URL+'/api/users/address/'+auth.userId,{
      headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer '+auth.token }
    })
      .then(result => result.json())
      .then(data => (isSubscribed ? setUserAddresses(data) : null))
    
    return () => (isSubscribed = false);
  }, [auth.userId, auth.token])

  useEffect(() => {
    let isSubscribed = true;
  
    fetch(process.env.REACT_APP_API_URL+'/api/users/'+auth.userId+'/cards',{
      headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer '+auth.token }
    })
      .then(result => result.json())
      .then(data => (isSubscribed ? setUserCards(data) : null))
    
    return () => (isSubscribed = false);
  }, [auth.userId, auth.token])

  useEffect(() => {
    let isSubscribed = true;
    fetch(process.env.REACT_APP_API_URL+'/api/general/clubs')
      .then(result => result.json())
      .then(data => (isSubscribed ? setCurrentSubId(data[0]._id) : null))
    return () => {
      isSubscribed = false
    }
  }, [])

  const handleNext = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1);
  }

  const handleBackHomeClick = () => {
    history.go("/");
  }

  const getStepContent = (stepIndex) => {
    console.log(userAddresses);

    if(stepIndex === 0){
      if(userAddresses && userAddresses.length === 0){
        return <AddressForm 
        onNextStep={handleNext}
        activeStep={activeStep}
        location="subscription"
        ></AddressForm>;
      }else{
        return <AddressList
        onNextStep={handleNext}
        activeStep={activeStep}
        userAddresses={userAddresses}
        location="subscription"
        ></AddressList>
      }
    }else if(stepIndex === 1){
      return <CardList
        onNextStep={handleNext}
        activeStep={activeStep}
        userCards={userCards}
        subId={currentSubId}
      ></CardList>
    }
  }
  
  return(
    <Grid 
      container         
      direction="row"
      justify="center"
      alignItems="center"
    >
      <Grid item xs={12} md={7} >
        <Paper elevation={3} className={classes.mainPaper}>
          <Stepper activeStep={activeStep} alternativeLabel>
            {steps.map(label => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <Grid container direction="row" justify="center" alignItems="center">
            {activeStep === steps.length ? (
              // all completed steps!
              <React.Fragment>
                <Grid item xs={12} md={12}>
                  <Typography variant="h3" align="center" className={classes.instructions} gutterBottom>
                    ¡Gracias por inscribirte al Club!
                  </Typography>
                  <Typography variant="h5" align="center" className={classes.instructions}>
                    Te contactaremos cuando tu caja esté en camino
                  </Typography>
                </Grid>
                <Grid item xs={12} md={2}>
                  <Box mb={3}>
                    <Button
                      color="secondary"
                      variant="contained"
                      fullWidth={true}
                      onClick={handleBackHomeClick}
                    >
                      <strong>Volver al home</strong>
                    </Button>
                  </Box>
                </Grid>
              </React.Fragment>
            ) : (
              <Grid item xs={12}>
                {/* step content! */}
                <Container className={classes.instructions}>{getStepContent(activeStep)}</Container>
              </Grid>
            )}
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  )
}

export default Subscription;