import React from 'react';
import { Grid, Paper, Box, Typography, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useHistory } from 'react-router-dom';


const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    marginTop: theme.spacing(3)
  }
}));

const ErrorInscription = props => {
  const classes = useStyles();
  let history = useHistory();

  return (
    <div className={classes.root}>
      <Grid 
      container
      direction="row"
      justify="center"
      alignItems="center"
      >
        <Grid item xs={12} md={4}>
          
          <Paper elevation={3}>
            <Box p={4}>
              <Typography variant="h5" align="left" gutterBottom={true}>
                Transacción Rechazada
              </Typography>
              <Box mb={2}>
                <Typography variant="body1" align="left" gytterBottom={true}>
                  La transacción a sido rechazada, las posibles causas de este rechazo son:
                </Typography>
              </Box>

              <Box mb={2}>
                <ul>
                  <li>Error en el ingreso de datos de su tarjeta de Crédito o Débito(fecha y/o código de seguridad)</li>
                  <li>Su tarjeta de Crédito o Débito no cuenta con el cupo necesario para cancelar la compra.</li>
                  <li>Tarjeta aun no habilitada en el sistema financiero.</li>
                  <li>El usuario a cancelado la transacción de forma manual.</li>
                </ul>
              </Box>

                <Button
                  color="secondary"
                  variant="contained"
                  fullWidth={true}
                  onClick={() => (history.push("/home"))}
                >
                  <strong>Volver al home</strong>
                </Button>

            </Box>
          </Paper>
        </Grid>

      </Grid>
    </div>
  );
}

export default ErrorInscription;