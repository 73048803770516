import React, { useState, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Container, Paper, FormControl, FormLabel, RadioGroup, 
  FormControlLabel, Radio, Grid, Button, FormHelperText, Dialog, DialogTitle, Box } 
  from '@material-ui/core';
import { AuthContext } from '../../shared/context/auth-context';
import AddressForm from './AddressForm';

const useStyles = makeStyles(theme => ({
  formControl: {
    width: '100%'
  },
  addressPaper: {
    padding: theme.spacing(2)
  },
  addressLabel: {
    marginBottom: theme.spacing(2)
  },
  buttonGrid: {
    marginTop: theme.spacing(3)
  },
  helperText: {
    marginTop: theme.spacing(3)
  }
}));


const AdressList = props => {
  const classes = useStyles();
  const [value, setValue] = useState(null);
  // const [showError, setShowError] = useState(false);
  const [error, setError] = useState({ show: false, msg: ''});
  const [open, setOpen] = React.useState(false);
  const auth = useContext(AuthContext);

  const handleNextStep = () => {
    props.onNextStep();
  }

  const handleChange = event => {
    setValue(event.target.value);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSelectAddress = event => {
    event.preventDefault();
    if(!value){
      // setShowError(true);
      setError({show: false, msg: ''});
    }else{
      const selectedAddress = props.userAddresses.find(el => el.street === value);
      if(props.location && props.location === "user_panel"){
        if(selectedAddress.apartment && selectedAddress.apartment !== "" && selectedAddress.apartment !== "-"){
          selectedAddress.street = selectedAddress.street+ ' Dpto/Of ' + selectedAddress.apartment
        }
        let data = {
          userId: auth.userId,
          idClub: props.idClub,
          address: selectedAddress
        }

        fetch(process.env.REACT_APP_API_URL+"/api/subscriptions/change-address", {
          method: 'POST',
          body: JSON.stringify(data),
          headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer '+auth.token }
        })
        .then(res => res.json())
        .then(res => { 
          // console.log(res);
          if(res.success){
            props.onUpdateAddress();
          }else{
            // alert("Comuna no incluida dentro del club de vinos")
            setError({show: true, msg: 'The BOX aún no está disponible en la comuna seleccionada'});

          }
        })
      }else if(props.location && props.location === "subscription"){
        const addressIndex = props.userAddresses.findIndex(el => el.street === value);
        if(addressIndex !== -1){
          if(selectedAddress.apartment && selectedAddress.apartment !== "" && selectedAddress.apartment !== "-"){
            selectedAddress.street = selectedAddress.street+ ' Dpto/Of ' + selectedAddress.apartment
          }
          const storedData = JSON.parse(localStorage.getItem('selectedAddress'));
          
          if(!storedData && selectedAddress){
            localStorage.setItem('selectedAddress', JSON.stringify({
              pos: addressIndex,
              address: selectedAddress
            }));
          }else if(selectedAddress){
            localStorage.removeItem('selectedAddress');
            localStorage.setItem('selectedAddress', JSON.stringify({
              pos: addressIndex,
              address: selectedAddress
            }));
          }
          
          fetch(process.env.REACT_APP_API_URL+'/api/users/mark-second-step/'+auth.userId+"/"+selectedAddress.state,{
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer '+auth.token }
          })
            .then(response => response.json())
            .then(response => {
              if(response.success){
                handleNextStep();
              }else{
                // alert("Comuna no incluida dentro del club de vinos")
                setError({show: true, msg: 'The BOX aún no está disponible en la comuna seleccionada'});
              }
            })
        }
      }
    }
  }

  return (
    <Container>
      <FormControl component="fieldset" className={classes.formControl}  error={error.show}>
        <FormLabel component="legend" className={classes.addressLabel}>Selecciona una dirección</FormLabel>
        <RadioGroup aria-label="adress" name="address" value={value} onChange={handleChange}>
          { props.userAddresses && props.userAddresses.map(address => (
            <Box key={address.street} mb={2}>
              <Paper elevation={3} className={classes.addressPaper}>
                {address.apartment && address.apartment !== "" && address.apartment !== "-" ?
                  <FormControlLabel 
                    value={address.street} 
                    control={<Radio />} 
                    label={address.street+' Dpto/Of '+ address.apartment+', '+address.state+', '+address.region+'.'} 
                  />
                  :
                  <FormControlLabel 
                    value={address.street} 
                    control={<Radio />} 
                    label={address.street+', '+address.state+', '+address.region+'.'} 
                  />
                }
                
              </Paper>
            </Box>

          ))}
        </RadioGroup>
        {error.show && (
          <FormHelperText className={classes.helperText}>{error.msg}</FormHelperText>
        )}
      </FormControl>
      <Grid container className={classes.buttonGrid} spacing={2} justify="space-between">
        <Grid item xs={12} md={4}>
          <Button
            variant="contained"
            onClick={handleClickOpen}
            fullWidth={true}
          >
            <strong>Agregar Direccion</strong>
          </Button>
        </Grid>

        <Grid item xs={12} md={4}>
          <Button 
            variant="contained" 
            color="secondary" 
            fullWidth={true}
            onClick={handleSelectAddress}
          >
            <strong>{ props.location === "subscription" ? 'Guardar' : 'Continuar' }</strong>
          </Button>
        </Grid>
      </Grid>
      
      <React.Fragment>
        <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="address-form-dialog-title">Agregar nueva dirección</DialogTitle>
          <Box m={2}>
            <AddressForm
              onNextStep={handleNextStep}
              activeStep={props.activeStep}
              idClub={props.idClub}
              location={props.location}
              onUpdateAddress={props.onUpdateAddress}
            ></AddressForm>
          </Box>
        </Dialog>
      </React.Fragment>
    </Container>
  )
}

export default AdressList;