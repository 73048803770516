import React, { useState, useContext, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Paper, Button, Typography, Dialog, DialogTitle, Box } from '@material-ui/core';
import HomeIcon from '@material-ui/icons/Home';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import { AuthContext } from '../../shared/context/auth-context';

// import CardChange from './CardChange';
import AddressList from '../../subscription/components/AddressList';
import CardChange from '../../user-panel/components/CardChange';

const useStyles = makeStyles(theme => ({
  mainPaper:{
    padding: theme.spacing(2)
  },
  buttonGrid:{
    marginTop: theme.spacing(1)
  }
}));

const SubList = props => {
  const classes = useStyles();
  const [openCardDialog, setOpenCardDialog] = useState(false);
  const [openAddressDialog, setOpenAddressDialog] = useState(false)
  const [userAddresses, setUserAddresses] = useState(null);
  const [selectedSubId, setSelectedSubId] = useState(null);
  const auth = useContext(AuthContext);

  const handleCardChangeClick = (id) => {
    setSelectedSubId(id);
    setOpenCardDialog(true);
  }

  const handleCloseCardDialog = () => {
    setOpenCardDialog(false)
  }

  function handleAddressChangeClick(id) {
    setSelectedSubId(id);
    setOpenAddressDialog(true);
  }

  const handleCloseAddressDialog = () => {
    setSelectedSubId(null);
    setOpenAddressDialog(false)
  }

  const handleUpdateAddress = () => {
    handleCloseAddressDialog();
    props.onUpdateData();
  }

  const handleUpdateCard = () => {
    handleCloseCardDialog();
    props.onUpdateData();
  }

  const handleChangeSubStatus = (idClub, status) => {
    console.log('quiere cancelar', idClub);

    let data = {
      userId: auth.userId,
      idClub: idClub,
      status: status
    }

    fetch(process.env.REACT_APP_API_URL+"/api/subscriptions/change-status", {
      method: 'POST',
      body: JSON.stringify(data),
      headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer '+auth.token }
    })
    .then(res => res.json())
    .then(res => { 
      console.log(res);
      props.onUpdateData();
    })
  }
  
  useEffect(() => {
    let isSubscribed = true;
  
    fetch(process.env.REACT_APP_API_URL+'/api/users/address/'+auth.userId, {
      headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer '+auth.token }
    })
      .then(result => result.json())
      .then(data => (isSubscribed ? setUserAddresses(data) : null))
    
    return () => (isSubscribed = false);
  }, [auth.userId, auth.token])

  return( 
    <Grid container spacing={2}>
      { props.userSubscriptions && 
        props.userSubscriptions.subscriptions.length > 0 && props.userSubscriptions.subscriptions.map(sub => (
        <Grid key={sub.idClub._id} item xs={12} md={6}>
          <Paper elevation={3} className={classes.mainPaper}>

            <Typography variant="h4" gutterBottom>{sub.idClub.name}</Typography>

            <Grid container spacing={2} direction="row" alignItems="center" justify="space-between">
              <Grid item xs={12}>
                <Typography variant="button" display="block">Dirección</Typography>
              </Grid>
              <Grid item xs={2} md={1}>
                <HomeIcon />
              </Grid>
              <Grid item xs={10} md={9}>
                {sub.address.street+', '+sub.address.state+', '+sub.address.region+'.'}
              </Grid>
              <Grid item xs={12} md={2}>
                <Button 
                  color="secondary" variant="contained" size="small" fullWidth={true} 
                  onClick={ () => handleAddressChangeClick(sub.idClub._id) }
                  >
                  <strong>Cambiar</strong>
                </Button>
              </Grid>
            </Grid>

            <Grid container spacing={2} direction="row" alignItems="center" justify="space-between">
              <Grid item xs={12}>
                <Typography variant="button" display="block">Medio de pago</Typography>
              </Grid>
              <Grid item xs={2} md={1}>
                <CreditCardIcon />
              </Grid>
              <Grid item xs={10} md={9}>
                {"Tarjeta de crédito terminada en: "+sub.cardNumber}
              </Grid>
              <Grid item xs={12} md={2}>
                <Button color="secondary" variant="contained" size="small" fullWidth={true} onClick={ () => handleCardChangeClick(sub.idClub._id)}>
                  <strong>Cambiar</strong>
                </Button>
              </Grid>
            </Grid>

            <Grid container spacing={2} direction="row" alignItems="center" justify="space-between" className={classes.buttonGrid}>
              <Grid item xs={12}>
                { sub.active && 
                  <Button 
                    color="primary" variant="contained" fullWidth={true}
                    onClick={ () => handleChangeSubStatus(sub.idClub._id, false)  }
                    >
                      <strong>Cancelar suscripción</strong>
                  </Button> 
                }

                { !sub.active &&
                  <Button 
                    color="secondary" variant="contained" fullWidth={true}
                    onClick={ () => handleChangeSubStatus(sub.idClub._id, true)  }
                    >
                      <strong>Reactivar suscripción</strong>
                  </Button> 
                }

              </Grid>
            </Grid>

          </Paper>
          {/* address dialog */}

        </Grid>        
      ))}

      <React.Fragment>
        <Dialog open={openAddressDialog} onClose={handleCloseAddressDialog} maxWidth="md" fullWidth={true}>
          <DialogTitle id="address-form-dialog-title">Cambiar dirección de despacho</DialogTitle>
          <Box m={2}>
            <AddressList
              userAddresses={userAddresses}
              location={props.location}
              idClub={selectedSubId}
              onUpdateAddress={handleUpdateAddress}
              onNextStep={handleUpdateAddress}
            ></AddressList>
          </Box>
        </Dialog>
      </React.Fragment>

      {/* card dialog */}
      <React.Fragment>
        <Dialog open={openCardDialog} onClose={handleCloseCardDialog} aria-labelledby="form-dialog-title">
          <DialogTitle id="address-form-dialog-title">Cambiar tarjeta de crédito</DialogTitle>
          <Box m={2}>
            <CardChange 
              idClub={selectedSubId}
              onUpdateCard={handleUpdateCard}
              ></CardChange>
          </Box>
        </Dialog>
      </React.Fragment>

    </Grid>
  )
};

export default SubList;