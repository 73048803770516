import React, { useState, useContext } from 'react';
import { Button, Box, TextField } from '@material-ui/core';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@material-ui/core';
// import { Alert } from '@material-ui/lab';
import BoozAlert from '../../shared/components/booz-alert';

import { AuthContext } from '../../shared/context/auth-context';

const QuickCodeDialog = props => {
  const [openQuickCodeDialog, setOpenQuickCodeDialog] = useState(false);
  const [showCodeConfirmation, setShowCodeConfirmation] = useState(false);
  const [quickCode, setQuickCode] = useState('');
  const [error, setError] = useState({ show: false, msg: '' });

  // const [showCodeError, setShowCodeError] = useState(false);
  // const [codeErrorText, setCodeErrorText] = useState('');
  const [showInputError, setShowInputError] = useState(false);
  const [inputErrorText, setInputErrorText] = useState('');
  const auth = useContext(AuthContext);

  const handleQuickCodeDialogClose = () => {
    setOpenQuickCodeDialog(false)
    setShowCodeConfirmation(false)
  }

  const handleSendCodeClick = () => {
    //logica de solicitar codigo
    let data = {
      email: props.userEmail,
    }

    fetch(process.env.REACT_APP_API_URL+"/api/users/send-quick-access-code", {
      method: 'POST',
      body: JSON.stringify(data),
      headers: { 'Content-Type': 'application/json' }
    })
    .then(res => {
      switch(res.status){
        case 200:
          setShowCodeConfirmation(true)
          break;
        case 404:
          setError({ show: true, msg: 'No encontramos un usuario vinculado al correo ingresado.' });
          break;
        case 500:
          setError({ show: true, msg: 'Ocurrió un error al procesar tus datos, por favor intente nuvamente.' });
          break;
        default:
          setError({ show: true, msg: 'Ocurrió un error inesperado, por favor intente nuvamente.' });
          break;
      }
      res.json()
    })
  }

  const handleVerifyCodeClick = () => {
    //logica de enviar codigo de confirmacion

    if(!/^[0-9]{6}$/i.test(quickCode)){
      setShowInputError(true);
      setInputErrorText('Porfavor ingrese un número de 6 dígitos')
    }else{
      let data = {
        email: props.userEmail,
        code: quickCode
      }
  
      fetch(process.env.REACT_APP_API_URL+"/api/users/verify-quick-access-code", {
        method: 'POST',
        body: JSON.stringify(data),
        headers: { 'Content-Type': 'application/json' }
      })
      .then(res => {
        
        res.json()
      })
      .then(res => { 
        if(res.userData){
          setOpenQuickCodeDialog(false)
          setShowCodeConfirmation(false)
          auth.login(res.userData, res.token)
        }else if(res.success === false){
          setError({show: true, msg: res.msg})
        }
        // console.log(res);
        setShowInputError(false);
        setInputErrorText('');
      })

    }
  }

  const handleAlertClose = (event) => {
    event.preventDefault();
    setError({ show: false, msg: '' });
  } 

  return (
    <React.Fragment>
      {props.userEmail && (
        <Button variant="contained" color="secondary" fullWidth={true} onClick={() => setOpenQuickCodeDialog(true)}>
          <strong>Clave de acceso rápido</strong>
        </Button>
      )}

      <Dialog
        open={openQuickCodeDialog}
        onClose={handleQuickCodeDialogClose}
        aria-labelledby="quick-code-dialog-title"
        aria-describedby="quick-code-dialog-description"
        maxWidth="xs"
      >
        
        {!showCodeConfirmation && (
          <React.Fragment>
            <DialogTitle id="quick-code-dialog-title">{"¿Confirma que desea recibir una clave de acceso rápido?"}</DialogTitle>
            <DialogContent>
              {error.show && (
                <Box mb={3}>
                  <BoozAlert 
                    show={error.show}
                    message={error.msg}
                    severity={'error'}
                    onClose={handleAlertClose}
                  />
                </Box>
              )}
              <DialogContentText id="quick-code-dialog-description">
                {"Será enviada al correo "+props.userEmail+" y tendrá una duración de 10 minutos"}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleQuickCodeDialogClose} variant="contained" fullWidth={true}>
                Volver
              </Button>
              <Button onClick={handleSendCodeClick} variant="contained" color="primary" fullWidth={true} autoFocus>
                <strong>Enviar</strong>
              </Button>
            </DialogActions>
          </React.Fragment>
        )}

        {showCodeConfirmation && (
          <React.Fragment>
            <DialogTitle id="quick-code-dialog-title">{"Acceso con código rápido"}</DialogTitle>
            <DialogContent>
              <DialogContentText>
                {"Ingresa el código que enviamos al correo: "+props.userEmail}
              </DialogContentText>

              {error.show && (
                <Box mb={3}>
                  <BoozAlert 
                    show={error.show}
                    message={error.msg}
                    severity={'error'}
                    onClose={handleAlertClose}
                  />
                </Box>
              )}
              <TextField
                autoFocus
                required
                error={showInputError}
                helperText={inputErrorText}
                margin="dense"
                id="quickcode"
                label="Código rápido"
                type="number"
                value={quickCode}
                onChange={e => setQuickCode(e.target.value)}
                fullWidth
              />
            </DialogContent>
            <DialogActions>
              <Box p={2} minWidth="100%">
                <Button onClick={handleVerifyCodeClick} variant="contained" color="primary" fullWidth={true}>
                  Ingresar
                </Button>
              </Box>
            </DialogActions>
          </React.Fragment>
        )}

      </Dialog>
    </React.Fragment>
  )
}

export default QuickCodeDialog;