import React, { useState } from 'react';
import { Grid, Box, Divider } from '@material-ui/core';

import EmailCheck from './EmailCheck';
import PasswordCheck from './PasswordCheck';
import QuickCodeDialog from './QuickCodeDialog';
import PasswordResetDialog from './PasswordResetDialog';

const LoginForm = (props) => {
  const [showEmailCheck, setShowEmailCheck] = useState(true);
  const [userEmail, setUserEmail] = useState(null);

  const toggleEmailCheck = (userEmail) => {
    setUserEmail(userEmail);
    setShowEmailCheck(false);
  }

  return (
    <Grid container>

      <Grid item xs={12}>
        { showEmailCheck ? 
          <EmailCheck onEmailChecked={toggleEmailCheck} /> : 
          <PasswordCheck userEmail = {userEmail} />
        }
      </Grid>


      {!showEmailCheck && ( 
        <React.Fragment>
          <Grid item xs={12}>
            <Box mt={2}>
              <Divider></Divider>        
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Box mb={2} mt={2}>
              <QuickCodeDialog userEmail={userEmail} />
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Box>
              <PasswordResetDialog userEmail={userEmail} />
            </Box>
          </Grid>
        </React.Fragment>
      )}

      
    </Grid>
  )
}

export default LoginForm;
