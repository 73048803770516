import React from 'react';
import PropTypes from 'prop-types';
import { Collapse } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

const BoozAlert = props => { 
    let { show, message, severity, onClose } = props;

    return (
      <Collapse in={ show }>
        {show && (
          <Alert severity={ severity } onClose={ onClose }>
            { message }
          </Alert>
        )}
      </Collapse>
    )
}

BoozAlert.propTypes = {
  show: PropTypes.bool.isRequired,
  message: PropTypes.string,
  severity: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired
}

export default BoozAlert;