import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import { Toolbar, Button, Container, Typography, Link, Box, Grid } from '@material-ui/core';
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';
import { Link as RouterLink } from 'react-router-dom';
import { AuthContext } from  '../../context/auth-context'

import logo from '../../../assets/images/navbar/the-box-logo.png';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  toolbar: {
    minHeight: 120,
    // display: 'flex',
    // justifyContent: 'space-around',
    // alignItems: 'center',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1)
  },
  title: {
    flexGrow: 1,
    maxWidth: 260,
    marginRight: theme.spacing(8),
    marginLeft: theme.spacing(6)
  },
  logo: {
    marginRight: theme.spacing(2)
  },
  link:{
    letterSpacing: "1.5px",
    color: '#fff'
  },
  linkContainer: {
    display: "flex",
    flexGrow: 1,
    justifyContent: "space-around",
  },
  buttonGroup: {
    display: "flex",
    flexGrow: 1,
    maxWidth: 320,
    justifyContent: "flex-end",
    alignItems: "center",
    marginRight: theme.spacing(4),
    marginLeft: theme.spacing(8),
  },
  boozColorLetter: {
    color: "#007ebc"
  },
  navButton: {
    marginLeft: theme.spacing(2)
  }
}));

const Navbar = props => {
  const classes = useStyles();
  const auth = useContext(AuthContext);

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Container maxWidth="lg">
          <Toolbar className={classes.toolbar}>
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
            >
              <Grid item md={3} lg={3}>
                <Link component={ RouterLink } underline="none" to="/">
                  <Typography variant="h6" className={classes.title} align="center">
                    <img className={classes.logo} src={logo} alt="Booz Club Vinos" height="49" width="180" />
                  </Typography>
                </Link>
              </Grid>

              <Grid item md={4} lg={4}>
                <Box>
                  <Typography align="left" variant="inherit">
                    <Link color="inherit" underline="none" href="https://www.booz.cl" target="_blank" className={classes.link}>
                      <strong>TIENDA ONLINE</strong>
                    </Link>
                  </Typography>
                </Box>
              </Grid>

              <Grid item md={5} lg={5}>
                <div className={classes.buttonGroup}>
                  {!auth.token && (
                    <Button component={RouterLink} className={classes.navButton} to="/auth" color="inherit" size="small" startIcon={<PersonOutlineOutlinedIcon />}>
                      <strong className={classes.link}>Iniciar Sesion</strong>
                    </Button>
                  )}

                  {auth.token && (
                    <Button component={RouterLink} className={classes.navButton} to='/logout' color="primary" variant="contained" size="small" >
                      <strong className={classes.link}>Cerrar sesión</strong>
                    </Button>
                  )}

                  
                  {auth.hasOneSub && (
                    <Button component={RouterLink} className={classes.navButton} to="/panel_usuario" color="secondary" aria-controls="Mi suscripcion" variant="outlined" size="small">
                      <strong className={classes.link}>Mi Suscripción</strong>
                    </Button>
                  )}
                  
                  {!auth.hasOneSub && (
                    <Button component={RouterLink} className={classes.navButton} to={ auth.token ? '/suscripcion' : '/auth' } color="secondary" variant="contained" size="small" >
                      <strong className={classes.link}>Suscríbete</strong>
                    </Button>
                  )}

                </div>
              </Grid>
            </Grid>

          </Toolbar>
        </Container>
      </AppBar>
    </div>
  );
}

export default Navbar;