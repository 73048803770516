import React from 'react';
import { BrowserRouter as Router, Route, Redirect, Switch } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Hidden from '@material-ui/core/Hidden';
import { createMuiTheme, responsiveFontSizes, ThemeProvider } from '@material-ui/core/styles';
import { CssBaseline } from '@material-ui/core';
import { ParallaxProvider } from 'react-scroll-parallax';

import ScrollToTop from './shared/components/ScrollToTop';
import Navbar from './shared/components/navbar/Navbar';
import MobileNavbar from './shared/components/navbar/MobileNavbar'
import Home from './home/pages/Home';
import HomeMobile from './home/pages/HomeMobile';
import Footer from './shared/components/footer/Footer';
import MobileFooter from './shared/components/footer/MobileFooter';
import Auth from './auth/pages/Auth';
import Subscription from './subscription/pages/Subscription';
import UserPanel from './user-panel/pages/UserPanel';
import ResetPassword from './auth/pages/ResetPassword';
import ErrorInscription from './tbk/pages/ErrorInscription';
import CompleteRegister from './tbk/pages/CompleteRegister';

import { AuthContext } from './shared/context/auth-context';
import { useAuth } from './shared/hooks/auth-hook';

//COPY FOR EXTRAXT URL ONECLICK
import { urlInscription } from './serviceWorker';

let theme = createMuiTheme({
  palette: {
    primary: {
      main: '#212121',
      light: '#484848',
      dark: '#000000',
      contrastText: '#ffffff'
    },
    secondary: {
      main: '#3fadef',
      light: '#7edfff',
      dark: '#007ebc',
      contrastText: '#ffffff'
    }
  },
  typography: {
    fontFamily: [
      'Century Gothic',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    fontWeightRegular: 400,
    fontWeightBold: 650,
    fontWeightLight: 300,
  },
});

theme = responsiveFontSizes(theme);

const useStyles = makeStyles(theme => ({
  main: {
    minHeight: "calc(100vh - 120px - 318px)"
  },
}));

const App = () => {
  const { token, login, logout, userId, userEmail, userFirstName, hasOneSub } = useAuth();
  const auth = useAuth(AuthContext);
  const classes = useStyles();

  let routes;
  const subId = '5e7106e6b086ef018b27b436';
  
  if(token) {
    routes = (
      <Switch>
        <Route path="/" exact>
          <Hidden mdDown>
            <Home></Home>
          </Hidden>
          <Hidden lgUp>
            <HomeMobile></HomeMobile>
          </Hidden>
        </Route>
        <Route path="/suscripcion" exact>
          <Subscription></Subscription>
        </Route>
        <Route path="/panel_usuario" exact>
          <UserPanel></UserPanel>
        </Route>
        <Route path="/error-inscripcion">
          <ErrorInscription></ErrorInscription>
        </Route>
        <Route path="/complete-register">
          <CompleteRegister></CompleteRegister>
        </Route>
        
        <Route
          path="/inscription-card"
          component={() => {
            global.window && (global.window.location.href = urlInscription(userId, subId, 0));
            return null;
          }}
        />
        <Route path="/logout"
          render={() => {
            auth.logout();
            window.location.reload();
            return <Redirect to={{ pathname: "/" }} />;
          }}
        />
        <Redirect to="/" />
      </Switch>
    );
  }else{
    routes = (
      <Switch>
        <Route path="/" exact>
          <Hidden mdDown>
            <Home></Home>
          </Hidden>
          <Hidden lgUp>
            <HomeMobile></HomeMobile>
          </Hidden>
        </Route>
        <Route path="/auth" exact>
          <Auth />
        </Route>
        <Route path="/error-inscripcion">
          <ErrorInscription></ErrorInscription>
        </Route>
        <Route path="/complete-register">
          <CompleteRegister></CompleteRegister>
        </Route>
        <Route path="/resetear-contrasena/:email/:token">
          <ResetPassword></ResetPassword>
        </Route>
        <Redirect to="/auth" />
      </Switch>
    )
  }

  return <ParallaxProvider>
    <ThemeProvider theme = {theme}>
      <CssBaseline />

      <AuthContext.Provider value={{
          isLoggedIn: !!token, 
          token: token,
          userId: userId,
          userEmail: userEmail,
          userFirstName: userFirstName,
          hasOneSub: hasOneSub,
          login: login, 
          logout: logout
        }} 
      >
        <Router>
          <ScrollToTop>
            <Hidden mdDown>
              <Navbar></Navbar>
            </Hidden>
            <Hidden lgUp>
              <MobileNavbar></MobileNavbar>
            </Hidden>
            <main className={classes.main}>
              {routes}
            </main>
            <Hidden mdDown>
              <Footer></Footer>
            </Hidden>
            <Hidden lgUp>
              <MobileFooter></MobileFooter>
            </Hidden>
          </ScrollToTop>
        </Router>
      </AuthContext.Provider>
    </ThemeProvider>
  </ParallaxProvider>;
}

export default App;
