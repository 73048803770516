import { useState, useCallback, useEffect } from 'react';

let logoutTimer;

export const useAuth = () => {
  const [token, setToken] = useState(false);
  const [tokenExpirationDate, setTokenExpirationDate] = useState();
  const [userId, setUserId] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const [userFirstName, setUserFirstName] = useState('');
  const [hasOneSub, setHasOneSub] = useState(false);

  const login = useCallback((userData, token, expirationDate) => { 
    setToken(token);
    setUserId(userData.userId);
    setUserEmail(userData.userEmail);
    setUserFirstName(userData.userFirstName);
    setHasOneSub(userData.hasOneSub);
    const tokenExpirationDate = expirationDate || new Date(new Date().getTime() + 1000 * 60 * 60);
    setTokenExpirationDate(tokenExpirationDate);
    localStorage.setItem('userData', JSON.stringify({
      userData: {
        userId: userData.userId,
        userEmail: userData.userEmail,
        userFirstName: userData.userFirstName,
        hasOneSub: userData.hasOneSub
      },
      token: token,
      expiration: tokenExpirationDate.toISOString(),
    }));
  }, [])

  const logout = useCallback(() => {
    setToken(null);
    setTokenExpirationDate(null);
    setUserId('');
    setUserEmail('');
    setUserFirstName('');
    setHasOneSub(false);
    localStorage.removeItem('userData');
    localStorage.removeItem('selectedAddress');
  }, [])

  useEffect(() => {
    if(token && tokenExpirationDate) {
      const remainingTime = tokenExpirationDate.getTime() - new Date().getTime();
      logoutTimer = setTimeout(logout, remainingTime)
    }else{
      clearTimeout(logoutTimer);
    }
  }, [token, logout, tokenExpirationDate])

  useEffect(() => {
    const storedData = JSON.parse(localStorage.getItem('userData'));
    if(storedData && storedData.token && new Date(storedData.expiration) > new Date() ) {
      login(storedData.userData, storedData.token, new Date(storedData.expiration));
    }
  }, [login]);

  return { token, login, logout, userId, userEmail, userFirstName, hasOneSub };
};