import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Formik, Form, Field } from 'formik';
import { TextField } from 'formik-material-ui';
import { makeStyles, Button, LinearProgress, Typography, Box } from '@material-ui/core';
import { AuthContext } from '../../shared/context/auth-context';
import BoozAlert from '../../shared/components/booz-alert';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  textField: {
    marginBottom: theme.spacing(2)
  }
}));

const PasswordCheck = props => {
  const [error, setError] = useState({ show: false, msg: '' });
  const auth = useContext(AuthContext);
  const userEmail = props.userEmail;
  const classes = useStyles();
  let history = useHistory();
  
  const handleAlertClose = (event) => {
    event.preventDefault();
    setError({ show: false, msg: '' });
  } 

  return (
    <div>
      <Box mb={3} display={error.show ? 'block' : 'none'}>
        <BoozAlert 
          show={error.show}
          message={error.msg}
          severity={'error'}
          onClose={handleAlertClose}
        />
      </Box>
      { userEmail && (
        <Typography component="div">
          <Box mb={2} textAlign="left" fontSize="body1.fontSize" letterSpacing={1}>
            Iniciando sesión como: <strong>{ userEmail }</strong>
          </Box>
        </Typography>
      )}

      <Formik
        validateOnBlur
        initialValues={{ password: '' }}
        validate={values => {
          const errors = {};
          if(!values.password){
            errors.password = 'Introduce tu contraseña';
          }
          return errors;
        }}
        onSubmit={(values, { setSubmitting }) => {
          let data = {
            email: userEmail,
            password: values.password
          }
          const sendRequest = async () => {
            const response = await fetch(process.env.REACT_APP_API_URL+'/api/users/login', {
              method: 'POST',
              body: JSON.stringify(data),
              headers: { 'Content-Type': 'application/json' }
            });
            switch(response.status){
              case 200:
                const responseData = await (await response).json();
                if(responseData.userData){
                  auth.login(responseData.userData, responseData.token)
                  if(responseData.userData.hasOneSub){
                    history.push("/panel_usuario");
                  }else{
                    history.push("/suscripcion");
                  }
                }
                break;
              case 401:
                setError({ show: true, msg: 'Contraseña incorrecta.' });
                break;
              case 404:
                setError({ show: true, msg: 'No encontramos un usuario vinculado al correo ingresado.' });
                break;
              case 500:
                setError({ show: true, msg: 'Ocurrió un error al procesar tus datos, por favor intente nuvamente.' });
                break;
              default:
                setError({ show: true, msg: 'Ocurrió un error inesperado, por favor intente nuvamente.' });
                break;
            }
            // const responseData = await (await response).json();
            // if(responseData.userData){
            //   auth.login(responseData.userData, responseData.token)
            //   if(responseData.userData.hasOneSub){
            //     history.push("/panel_usuario");
            //   }else{
            //     history.push("/suscripcion");
            //   }
            // }
          }
          sendRequest();
          setSubmitting(false);
        }}
      >
        {({ submitForm, isSubmitting }) => (
          <Form>
            <Field 
              component={TextField}
              className={classes.textField}
              label="Ingresa tu contraseña"
              variant="outlined"
              fullWidth={true}
              size="small"
              name="password"
              type="password"
            />
            {isSubmitting && <LinearProgress />}
            <br />
            <Button 
              fullWidth={true} 
              variant="contained" 
              color="primary"
              disabled={isSubmitting}
              onClick={submitForm}
            >
              <strong>Continuar</strong>
            </Button>
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default PasswordCheck;
