import React, { useContext } from 'react';
// import Image from 'material-ui-image';
import { Link as RouterLink } from 'react-router-dom';
import { Container, Box, Grid, Typography, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Parallax } from 'react-scroll-parallax';
import { AuthContext } from  '../../shared/context/auth-context'

//material ui for LISTS
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import CheckIcon from '@material-ui/icons/Check';

// import RegisterForm from '../../auth/components/RegisterForm';

import winebox from '../../assets/images/home/caja-the-box.png';
import winebottles from '../../assets/images/home/vinos-the-box.png';
import products from '../../assets/images/home/the-box-elementos-web.png';
import mainbanner from '../../assets/images/home/banner-web-principal-the-box.jpg';
import boxes from '../../assets/images/home/the-box-crear-una-cuenta.png';
import price from '../../assets/images/home/the-box-precio.png';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  logo: {
    padding: theme.spacing(2)
  },
  logoBox: {
    margin: theme.spacing(3)
  },
  mainBox: {
    backgroundColor: "#000",
    backgroundClip: "padding-box",
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8)
  },
  logoSubtitleBox: {
    padding: theme.spacing(2),
    width: "100%"
  },
  logoSubtitle: {
    color: "#fff",
    letterSpacing: "1.5vw",
    overflow: "hidden",
    marginLeft: theme.spacing(4),
    fontWeight: 700
  },
  productsLeft: {
    position: "relative",
    display: "block",
    height: "475px",
    marginTop: "-12em",
    marginLeft: 0,
    maxWidth: "765px",
    backgroundImage: `url(${products})`,
    backgroundRepeat: 'no-repeat'
    // width="660" height="475"
  },
  bannerContainer: {
    paddingTop: theme.spacing(6),
    maxHeight: "350px"
  },
  subscribeButton: {
    fontSize: '1.6em',
    marginTop: theme.spacing(2),
    letterSpacing: '.2vw',
  },
  parallaxRoot:{
    display: "flex",
    flexFlow: "row wrap",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh"
  },
  parallaxContainer: {
    position: "relative",
    width: "25em"
  },
  parallaxRoot2: {
    position: "relative",
    display: "flex",
    flexFlow: "column wrap",
    alignItems: "flex-start",
    justifyContent: "center",
    zIndex: 1
  },
  wineBoxParallax: {
    position: "absolute",
    top: "0em",
    left: 0,
    right: 0,
    zIndex: 100
  },
  wineBox: {
    position: "relative",
    // backgroundColor: "#000",
    backgroundImage: `url(${winebox})`,
    height: "460px",
    width: "345px"
  },
  wineBottlesParallax: {
    position: "absolute",
    top: "-1em",
    left: 0,
    right: 0,
    zIndex: 10
  },
  wineBottles: {
    position: "relative",
    // backgroundColor: "#ff0000",
    backgroundImage: `url(${winebottles})`,
    height: "460px",
    width: "345px"
  },
  parallaxGrid: {
    minHeight: "800px"
  },
  threeColCenter: {
    height: "480px"
  },
  textSecondary: {
    color: "#3fadef"
  },
  theBox: {
    color: "black"
  },
  theBoxO: {
    color: "#00adef"
  },
  textTerciary: {
    border: "1px dotted red",
    padding: "3px"
  },
  pricetagText: {
    fontWeight: 900,
    textShadow: "-3px 0px 0px rgba(63, 173, 239, 1)"
  },
  textSecondaryBold: {
    fontWeight: 900,
    color: "#3fadef"
  }


}));

const Home = () => {  
  // const imgStyle = {'height': 600}
  const classes = useStyles();
  // const [openRegisterDialog, setopenRegisterDialog] = useState(false)
  const auth = useContext(AuthContext);

  // const handleCloseRegisterDialog = () => {
  //   setopenRegisterDialog(false);
  // }

  return (
    <div>
      <Box>
        <img src={mainbanner} alt="Booz Club Vinos" height="100%" width="100%" />
      </Box>

      <Container className={classes.bannerContainer}>
        <Grid container   
            direction="row"
            justify="space-between"
            alignItems="center"
            spacing={2}
          >
          <Grid item xl={7} lg={7}>
            <Box className={classes.productsLeft}></Box>
          </Grid>
          <Grid item xl={5} lg={5}>
            <Box mb={15}>
              <Box mb={2}>
                <Typography component="div" >
                  <Box textAlign="left" className={classes.textSecondary} fontWeight="fontWeightBold" fontSize="h4.fontSize" letterSpacing={3}>
                    Descubre vinos distintos todos los meses con...
                  </Box>
                  <Box textAlign="left" className={classes.theBox} fontWeight="fontWeightBold" fontSize="h3.fontSize" letterSpacing={3}>
                    THE B<strong className={classes.theBoxO}>O</strong>X
                  </Box>
                </Typography>
              </Box>
              <Box pr={30}>
                <Typography component="div" >
                  <Box mb={1} textAlign="left" fontWeight="fontWeightBold" fontSize="body1.fontSize" letterSpacing={2}>
                    POR SÓLO
                  </Box>
                  <Box>
                    <img src={price} alt="$19.990" height="100%" width="100%"/>
                  </Box>
                  <Box textAlign="right" fontSize="body1.fontSize" letterSpacing={2}>
                    <strong>AL MES</strong>
                  </Box>
                  <Box textAlign="left" fontSize="body1.fontSize">
                    <strong>*incluye despacho*</strong>
                  </Box>
                </Typography>
              </Box>

              {auth.hasOneSub && (
                <Button component={RouterLink} className={classes.subscribeButton} to="/panel_usuario" color="secondary" aria-controls="Mi suscripcion" variant="contained">
                  <strong className={classes.link}>Mi Suscripción</strong>
                </Button>
              )}
              
              {!auth.hasOneSub && (
                <Button component={RouterLink} className={classes.subscribeButton} to={ auth.token ? '/suscripcion' : '/auth' } color="secondary" variant="contained">
                  <strong className={classes.link}>Suscríbete</strong>
                </Button>
              )}

            </Box>

          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container   
          direction="row"
          justify="center"
          alignItems="center"
          className={classes.parallaxGrid}
        >
          <Grid item md={4}>
            <Typography component="div">
              <Box textAlign="left" fontWeight="fontWeightBold" fontSize="h4.fontSize" letterSpacing={3}>
                RECIBE TU PRIMERA CAJA EN <span className={classes.textSecondary}>4 DÍAS HÁBILES</span> LUEGO DE SUSCRIBIRTE
              </Box>
              <Box textAlign="left" fontSize="h6.fontSize" letterSpacing={2}>
                Y TODOS LOS MESES RECIBES LA FECHA QUE TE SUSCRIBISTE + 4 DÍAS
              </Box>
            </Typography>
          </Grid>
          <Grid item md={4}>
            <Box className={classes.parallaxRoot2}>
              <Box className={classes.parallaxContainer}>
                <Parallax
                    y={[0, -100]}
                    className={classes.wineBoxParallax}
                >
                  <Box className={classes.wineBox}></Box>
                </Parallax>

                <Parallax
                  y={[0, 40]}
                  className={classes.wineBottlesParallax}
                >
                  <Box className={classes.wineBottles}></Box>
                </Parallax>

              </Box>
            </Box>
          </Grid>
          <Grid item md={4}>
            <Typography component="div">
              <List className={classes.root}>
                <ListItem>
                  <ListItemAvatar>
                    <Avatar>
                      <CheckIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary="Todos los meses vinos distintos." secondary="Por mínimo 1 año." />
                </ListItem>
                <ListItem>
                  <ListItemAvatar>
                    <Avatar>
                      <CheckIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary="40% de descuento mínimo, respecto a precios retail." secondary="No aplica a promociones y ofertas." />
                </ListItem>
                <ListItem>
                  <ListItemAvatar>
                    <Avatar>
                      <CheckIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary="Suscríbete ahora y recibe estos 3 vinos." secondary="Sí, los mismos de la imagen." />
                </ListItem>
                <ListItem>
                  <ListItemAvatar>
                    <Avatar>
                      <CheckIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary="Vinos desde clasificación Reserva, garantizado." secondary="Reservas de excelente calidad." />
                </ListItem>
                <ListItem>
                  <ListItemAvatar>
                    <Avatar>
                      <CheckIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary="Congela o termina tu suscripción rápido y ¡Cuando quieras!" secondary="Así es, sólo dando click a un botón." />
                </ListItem>
                <ListItem>
                  <ListItemAvatar>
                    <Avatar>
                      <CheckIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary="Te mandamos por correo información de tus vinos." secondary="Un PDF para que puedas verlo desde tu celular." />
                </ListItem>
              </List>
            </Typography>
            {!auth.token && (
              <Button component={ RouterLink } to="/auth" color="secondary" variant="contained" className={classes.subscribeButton}>
                <strong>EMPEZAR AHORA</strong>
              </Button>
            )}

            {auth.token && (
              <Button component={ RouterLink } to={ auth.hasOneSub ? '/panel_usuario' : '/suscripcion' } color="secondary" variant="contained" className={classes.subscribeButton}>
                <strong>EMPEZAR AHORA</strong>
              </Button>
            )}
          </Grid>
        </Grid>
      </Container>

      <Container className={classes.bannerContainer}>
        <Grid container   
          direction="row"
          justify="center"
          alignItems="flex-start"
        >
          <Grid item md={4}>
            <Typography component="div">
              <Box textAlign="right" fontWeight="fontWeightBold" fontSize="h4.fontSize" letterSpacing={3}>
                TODOS LOS MESES UNA
              </Box>
              <Box textAlign="right" fontWeight="fontWeightBold" fontSize="h3.fontSize" letterSpacing={3} className={classes.textSecondary}>
                NUEVA EXPERIENCIA
              </Box>
            </Typography>
          </Grid>
          <Grid item md={4} className={classes.threeColCenter}>

          </Grid>
          <Grid item md={4}>
            <Typography component="div">
              <Box textAlign="right" fontWeight="fontWeightBold" fontSize="h4.fontSize" letterSpacing={6}>
                DESCORCHA TUS VINOS
              </Box>
              <Box textAlign="right" fontWeight="fontWeightBold" fontSize="h2.fontSize" letterSpacing={6}>
                Y <span className={classes.textSecondary}>DISFRUTA</span> 
              </Box>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container   
          direction="row"
          justify="center"
          alignItems="center"
          spacing={6}
        >
          <Grid item md={5} lg={5}>
            <Box ml={6}>
              <Typography component="div">
                <Box textAlign="left" fontWeight="fontWeightBold" fontSize="h3.fontSize" letterSpacing={6}>
                  ENCUENTRA LA
                </Box>
                <Box textAlign="left" fontWeight="fontWeightBold" fontSize="h3.fontSize" letterSpacing={6} className={classes.textSecondary}>
                  BOTELLA DE VINO
                </Box>
                <Box textAlign="left" fontWeight="fontWeightBold" fontSize="h3.fontSize" letterSpacing={6} mb={2}>
                  PERFECTA PARA TI
                </Box>
              </Typography>

              {auth.hasOneSub && (
                <Button component={RouterLink} className={classes.subscribeButton} to="/panel_usuario" color="secondary" aria-controls="Mi suscripcion" variant="contained">
                  <strong className={classes.link}>Mi Suscripción</strong>
                </Button>
              )}
              
              {!auth.hasOneSub && (
                <Button component={RouterLink} className={classes.subscribeButton} to={ auth.token ? '/suscripcion' : '/auth' } color="secondary" variant="contained">
                  <strong className={classes.link}>Suscríbete</strong>
                </Button>
              )}
            </Box>
          </Grid>
          <Grid item md={7} lg={7}>
            <img src={boxes} alt="Cajas The Box Wine Club" height="650" width="650" />
          </Grid>
        </Grid>
      </Container>
    </div>
    
  )
};

export default Home;