import React, { useContext } from 'react';
// import Image from 'material-ui-image';
import { Link as RouterLink } from 'react-router-dom';
import { Box, Grid, Typography, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
// import { Parallax } from 'react-scroll-parallax';
import { AuthContext } from  '../../shared/context/auth-context'

//material ui for LISTS
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import CheckIcon from '@material-ui/icons/Check';

// import RegisterForm from '../../auth/components/RegisterForm';

import winebox from '../../assets/images/home/caja-the-box.png';
import winebottles from '../../assets/images/home/botellas-de-vino-the-box.png';
import products from '../../assets/images/home/movil/the-box-elementos-movil.png';
import mainbanner from '../../assets/images/home/movil/banner-movil-principal-the-box.jpg';
import boxes from '../../assets/images/home/movil/the-box-crear-una-cuenta-movil.png';
import seccion2 from '../../assets/images/home/movil/the-box-seccion-2-movil.png';
import seccion3 from '../../assets/images/home/movil/the-box-seccion-3-movil.png';
import price from '../../assets/images/home/the-box-precio.png';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  logo: {
    padding: theme.spacing(2)
  },
  logoBox: {
    margin: theme.spacing(3)
  },
  mainBox: {
    backgroundColor: "#000",
    backgroundClip: "padding-box",
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8)
  },
  logoSubtitleBox: {
    padding: theme.spacing(2),
    width: "100%"
  },
  logoSubtitle: {
    color: "#fff",
    letterSpacing: "1.5vw",
    overflow: "hidden",
    marginLeft: theme.spacing(4),
    fontWeight: 700
  },
  products: {
    position: "relative",
    display: "block",
    height: "268px",
    width: "320px",
    marginTop: "-4em",
    marginRight: ".2em",
    backgroundImage: `url(${products})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover'
    // width="660" height="475"
  },
  theBox: {
    color: "black",
    textAlign: "center"
  },
  theBoxO: {
    color: "#00adef"
  },
  bannerContainer: {
    paddingTop: theme.spacing(6),
    maxHeight: "350px"
  },
  subscribeButton: {
    fontSize: '1.6em',
    marginTop: theme.spacing(2),
    letterSpacing: '.2vw',
  },
  parallaxRoot:{
    display: "flex",
    flexFlow: "row wrap",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh"
  },
  parallaxContainer: {
    position: "relative",
    width: "25em"
  },
  parallaxRoot2: {
    position: "relative",
    display: "flex",
    flexFlow: "column wrap",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 1
  },
  wineBoxParallax: {
    position: "absolute",
    top: "15em",
    left: 0,
    right: 0,
    zIndex: 100
  },
  wineBox: {
    position: "relative",
    // backgroundColor: "#000",
    backgroundImage: `url(${winebox})`,
    height: "460px",
    width: "345px"
  },
  wineBottlesParallax: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    zIndex: 10
  },
  wineBottles: {
    position: "relative",
    // backgroundColor: "#ff0000",
    backgroundImage: `url(${winebottles})`,
    height: "345px",
    width: "345px"
  },
  parallaxGrid: {
    minHeight: "480px"
  },
  threeColCenter: {
    height: "480px"
  },
  textSecondary: {
    color: "#3fadef"
  },
  pricetagText: {
    fontWeight: 900,
    textShadow: "-3px 0px 0px rgba(63, 173, 239, 1)"
  },
  textSecondaryBold: {
    fontWeight: 900,
    color: "#3fadef"
  },
  greyBackground:{
    backgroundColor: "#f0f0f0",
    backgroundClip: "padding-box"
  }


}));

const Home = () => {  
  // const imgStyle = {'height': 600}
  const classes = useStyles();
  // const [openRegisterDialog, setopenRegisterDialog] = useState(false)
  const auth = useContext(AuthContext);

  // const handleCloseRegisterDialog = () => {
  //   setopenRegisterDialog(false);
  // }

  return (
    <div>
      <Box>
        <img src={mainbanner} alt="Booz Club Vinos" height="100%" width="100%" />
      </Box>

      <Box>
        <Grid container   
          direction="column"
          justify="center"
          alignItems="center"
        >
          <Grid item xs={12}>
            <Box p={2} className={classes.products}></Box>
          </Grid>

          <Grid item xs={10}>
            <Box mb={3}>
              <Box mb={2}>
                <Typography component="div">
                  <Box textAlign="left" className={classes.textSecondary} fontWeight="fontWeightBold" fontSize="h4.fontSize" letterSpacing={3}>
                    Descubre vinos distintos todos los meses con...
                  </Box>
                  <Box textAlign="left" className={classes.theBox} fontWeight="fontWeightBold" fontSize="h3.fontSize" letterSpacing={3}>
                    THE B<strong className={classes.theBoxO}>O</strong>X
                  </Box>
                </Typography>
              </Box>

              <Box pl={3} pr={3}>
                <Typography component="div">
                  <Box textAlign="left" fontWeight="fontWeightBold" fontSize="overline.fontSize" letterSpacing={2}>
                    POR SOLO
                  </Box>
                  <Box display="flex" justifyContent="center">
                    <img src={price} alt="$19.990" height="80%" width="80%"/>
                  </Box>
                  <Box mb={1} textAlign="right" fontSize="overline.fontSize" letterSpacing={2}>
                    <strong>AL MES</strong>
                  </Box>
                  <Box textAlign="center">
                    *incluye despacho* 
                  </Box>
                </Typography>
              </Box>

              {auth.hasOneSub && (
                <Button component={RouterLink} className={classes.subscribeButton} to="/panel_usuario" color="secondary" aria-controls="Mi suscripcion" variant="contained" fullWidth={true}>
                  <strong className={classes.link}>Mi Suscripción</strong>
                </Button>
              )}
              
              {!auth.hasOneSub && (
                <Button component={RouterLink} className={classes.subscribeButton} to={ auth.token ? '/suscripcion' : '/auth' } color="secondary" variant="contained" fullWidth={true}>
                  <strong className={classes.link}>Suscríbete</strong>
                </Button>
              )}

              <List className={classes.root}>
                <ListItem>
                  <ListItemAvatar>
                    <Avatar>
                      <CheckIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary="Todos los meses vinos distintos." secondary="Por mínimo 1 año." />
                </ListItem>
                <ListItem>
                  <ListItemAvatar>
                    <Avatar>
                      <CheckIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary="40% de descuento mínimo, respecto a precios retail." secondary="No aplica a promociones y ofertas." />
                </ListItem>
                <ListItem>
                  <ListItemAvatar>
                    <Avatar>
                      <CheckIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary="Suscríbete ahora y recibe estos 3 vinos." secondary="Sí, los mismos de la imagen." />
                </ListItem>
                <ListItem>
                  <ListItemAvatar>
                    <Avatar>
                      <CheckIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary="Vinos desde clasificación Reserva, garantizado." secondary="Reservas de excelente calidad." />
                </ListItem>
                <ListItem>
                  <ListItemAvatar>
                    <Avatar>
                      <CheckIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary="Congela o termina tu suscripción rápido y ¡Cuando quieras!" secondary="Así es, sólo dando click a un botón." />
                </ListItem>
                <ListItem>
                  <ListItemAvatar>
                    <Avatar>
                      <CheckIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary="Te mandamos por correo información de tus vinos." secondary="Un PDF para que puedas verlo desde tu celular." />
                </ListItem>
              </List>

              <Box textAlign="center" fontSize="overline.fontSize" letterSpacing={1}>
                RECIBE TU PRIMERA CAJA EN <span className={classes.textSecondary}>4 DÍAS HÁBILES</span> LUEGO DE SUSCRIBIRTE
              </Box>
              <Box textAlign="center" fontSize="overline.fontSize" letterSpacing={1}>
                Y TODOS LOS MESES RECIBES LA FECHA QUE TE SUSCRIBISTE + 4 DÍAS
              </Box>

            </Box>

          </Grid>
        </Grid>
      </Box>

      <Box className={classes.greyBackground}>
        <Grid container   
          direction="column"
          justify="center"
          alignItems="center"
        >
          <Grid item xs={12}>
            <img src={seccion2} alt="Vinos y Boletín" height="270" width="310" />         
          </Grid>

          <Grid item xs={10}>
            <Box mb={3}>
              <Box pt={2} mb={2}>
                <Typography component="div">
                  <Box textAlign="center" className={classes.textSecondary} fontWeight="fontWeightBold" fontSize="h4.fontSize" letterSpacing={2}>
                    DESCORCHA Y
                  </Box>
                  <Box mb={1} textAlign="center" className={classes.textSecondary} fontWeight="fontWeightBold" fontSize="h4.fontSize" letterSpacing={2}>
                    DISFRUTA
                  </Box>
                  <Box mb={1} textAlign="center" fontSize="h6.fontSize" letterSpacing={1}>
                    <strong>40% DE DESCUENTO, respecto a precios retail.</strong> 
                  </Box>
                  <Box textAlign="center" fontSize="overline.fontSize" letterSpacing={1}>
                    No aplica a promociones y ofertas.
                  </Box>
                </Typography>
              </Box>

              {auth.hasOneSub && (
                <Button component={RouterLink} className={classes.subscribeButton} to="/panel_usuario" color="secondary" aria-controls="Mi suscripcion" variant="contained" fullWidth={true}>
                  <strong className={classes.link}>Mi Suscripción</strong>
                </Button>
              )}
              
              {!auth.hasOneSub && (
                <Button component={RouterLink} className={classes.subscribeButton} to={ auth.token ? '/suscripcion' : '/auth' } color="secondary" variant="contained" fullWidth={true}>
                  <strong className={classes.link}>EMPEZAR AHORA</strong>
                </Button>
              )}

            </Box>

          </Grid>
        </Grid>
      </Box>

      <Box>
        <Grid container   
          direction="column"
          justify="center"
          alignItems="center"
        >
          <Grid item xs={12}>
            <img src={seccion3} alt="Vinos y Boletín" height="270" width="310" />         
          </Grid>

          <Grid item xs={10}>
            <Box mb={2}>
              <Box pt={2} mb={2}>
                <Typography component="div">
                  <Box textAlign="center" className={classes.textSecondary} fontWeight="fontWeightBold" fontSize="h4.fontSize" letterSpacing={2}>
                    TODOS LOS MESES
                  </Box>
                  <Box mb={1} textAlign="center" className={classes.textSecondary} fontWeight="fontWeightBold" fontSize="h5.fontSize" letterSpacing={2}>
                    UNA NUEVA EXPERIENCIA
                  </Box>
                  <Box textAlign="center" fontSize="overline.fontSize" letterSpacing={1}>
                    EXCELENTE CALIDAD <span className={classes.textSecondary}>A UN GRAN PRECIO</span>.
                  </Box>
                </Typography>
              </Box>

              {auth.hasOneSub && (
                <Button component={RouterLink} className={classes.subscribeButton} to="/panel_usuario" color="secondary" aria-controls="Mi suscripcion" variant="contained" fullWidth={true}>
                  <strong className={classes.link}>Mi Suscripción</strong>
                </Button>
              )}
              
              {!auth.hasOneSub && (
                <Button component={RouterLink} className={classes.subscribeButton} to={ auth.token ? '/suscripcion' : '/auth' } color="secondary" variant="contained" fullWidth={true}>
                  <strong className={classes.link}>Suscríbete</strong>
                </Button>
              )}

            </Box>

          </Grid>
        </Grid>
      </Box>

      <Box className={classes.greyBackground}>
        <Grid container   
          direction="column"
          justify="center"
          alignItems="center"
        >
          <Grid item xs={12}>
            <img src={boxes} alt="Cajas The Box Wine Club" height="270" width="310" />  
          </Grid>

          <Grid item xs={12}>
            <Box mb={2}>
              <Box mb={2}>
                <Typography component="div">
                  <Box textAlign="center" fontWeight="fontWeightBold" fontSize="h5.fontSize" letterSpacing={2}>
                    ENCUENTRA LA
                  </Box>
                  <Box textAlign="center" className={classes.textSecondary} fontWeight="fontWeightBold" fontSize="h5.fontSize" letterSpacing={2}>
                    BOTELLA DE VINO
                  </Box>
                  <Box textAlign="center" fontWeight="fontWeightBold" fontSize="h5.fontSize" letterSpacing={1}>
                    PERFECTA PARA TI
                  </Box>
                </Typography>
              </Box>

              {/* <Button color="secondary" variant="contained" fullWidth={true} className={classes.subscribeButton} onClick={() => setopenRegisterDialog(true)}>
                <strong>CREA UNA CUENTA</strong>
              </Button> */}

              {auth.hasOneSub && (
                <Button component={RouterLink} className={classes.subscribeButton} to="/panel_usuario" color="secondary" aria-controls="Mi suscripcion" variant="contained" fullWidth={true}>
                  <strong className={classes.link}>Mi Suscripción</strong>
                </Button>
              )}
              
              {!auth.hasOneSub && (
                <Button component={RouterLink} className={classes.subscribeButton} to={ auth.token ? '/suscripcion' : '/auth' } color="secondary" variant="contained" fullWidth={true}>
                  <strong className={classes.link}>Suscríbete</strong>
                </Button>
              )}

            </Box>

          </Grid>
        </Grid>
      </Box>
      {/* <React.Fragment>
        <Dialog open={openRegisterDialog} onClose={handleCloseRegisterDialog} maxWidth="xs">
          <DialogTitle>¡Crea tu cuenta!</DialogTitle>
          <Box pl={2} pr={2} pb={2}>
            <RegisterForm></RegisterForm>
          </Box>
        </Dialog>
      </React.Fragment> */}
    </div>
    
  )
};

export default Home;