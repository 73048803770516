import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { Grid, Paper, Box, Button, TextField, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import BoozAlert from '../../shared/components/booz-alert';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    marginTop: theme.spacing(3)
  },
  textField: {
    marginBottom: theme.spacing(2)
  },
  alert: {
    marginBottom: theme.spacing(3)
  }
}));

const ResetPassword = props => {
  let { token, email } = useParams();
  // const [showError, setShowError] = useState(false);
  // const [errorMsj, setErrorMsj] = useState('');
  const [error, setError] = useState({ show: false, msg: '' });
  const [showChangedPassword, setShowChangedPassword] = useState(false);
  let history = useHistory();

  const [form, setForm] = useState({
    password: '',
    confirm: ''
  })

  const [errors, setErrors] = useState({
    password: '', 
    confirm: ''
  })

  const classes = useStyles();

  const handleAlertClose = (event) => {
    event.preventDefault();
    setError({ show: false, msg: '' });
  } 

  const updateField = e => {
    setForm({
      ...form,
      [e.target.name]: e.target.value
    })
  }

  const handleSubmit = e => {
    e.preventDefault();

    const validForm = validateFields();
    console.log(validForm);
    
    if(validForm){
      const data = {
        email: token,
        token: email,
        password: form.password
      }

      fetch(process.env.REACT_APP_API_URL+'/api/users/reset-password', {
        method: 'POST',
        body: JSON.stringify(data),
        headers: { 'Content-Type': 'application/json' }
      })
      .then(res => res.json())
      .then(res => { 
        if(res.success === true){
          // setErrorMsj('');
          // setShowError(false);
          setError({ show: false, msg: '' });
          setShowChangedPassword(true)
        }else if(res.success === false){
          setError({ show: true, msg: res.msg });
          // setErrorMsj(res.msg);
          // setShowError(true);
        }else{
          setError({ show: true, msg: 'Ocurrió un error inesperado, por favor intente más tarde.' });
          // setErrorMsj('Ocurrió un error inesperado, porfavor intente nuevamente más tarde.');
          // setShowError(true);
        }
        // console.log(res);
      })

    }

  }

  const validateFields = () => {
    if(!form.password){
      setErrors({
        ...errors,
        password: 'Introduce tu contraseña'
      })
      return false;
    }else if(form.password.length < 6){
      setErrors({
        ...errors,
        password: 'La contraseña debe tener al menos seis carácteres'
      })
      return false;
    }else if( !/^[0-9a-zA-Z]+$/.test(form.password) ){
      setErrors({
        ...errors,
        password: 'La contraseña solo puede tener números y letras'
      })
      return false;
    }else{
      setErrors({
        ...errors,
        password: ''
      })
    }

    if(form.confirm !== form.password){
      setErrors({
        ...errors,
        confirm: 'Las contraseñas no coinciden'
      })
      return false;
    }else{
      setErrors({
        ...errors,
        confirm: ''
      })
    }

    return true;
  }

  return (
    <div className={classes.root}>
      <Grid 
      container
      direction="row"
      justify="center"
      alignItems="center"
      >
        <Grid item xs={12} md={4} lg={3}>
          
          <Paper elevation={3}>
            <Box p={2}>
              <Box mb={3} display={error.show ? 'block' : 'none'}>
                <BoozAlert 
                  show={error.show}
                  message={error.msg}
                  severity={'error'}
                  onClose={handleAlertClose}
                />
              </Box>

              {showChangedPassword && (
                <React.Fragment>
                  <Typography component="div" >
                    <Box mb={3} textAlign="center"  fontWeight="fontWeightBold" fontSize="h4.fontSize">
                      ¡Contraseña cambiada exitosamente!
                    </Box>
                    <Button
                      color="secondary"
                      variant="contained"
                      fullWidth={true}
                      onClick={() => (history.go("/auth"))}
                    >
                      <strong>Volver al login</strong>
                    </Button>
                  </Typography>
                </React.Fragment>
              )}

              {!showChangedPassword && (
                <React.Fragment>
                  <Typography component="div" >
                    <Box mb={3} textAlign="left"  fontWeight="fontWeightBold" fontSize="h4.fontSize">
                      Cambiar contraseña
                    </Box>
                  </Typography>
                
                  <form onSubmit={handleSubmit}>
                    <Box mb={2}>
                      <TextField
                        error={errors.password !== ''}
                        label="Ingresa tu contraseña"
                        helperText={errors.password}
                        variant="outlined"
                        size="small"
                        name="password"
                        type="password"
                        fullWidth={true}
                        value={form.password}
                        onChange={updateField}
                      />
                    </Box>

                    <Box mb={2}>
                      <TextField
                        error={errors.confirm !== ''}
                        label="Confirma tu contraseña"
                        helperText={errors.confirm}
                        variant="outlined"
                        size="small"
                        name="confirm"
                        type="password"
                        fullWidth={true}
                        value={form.confirm}
                        onChange={updateField}
                      />
                    </Box>

                    <Box mb={2}>
                      <Button 
                        fullWidth={true} 
                        variant="contained" 
                        color="primary"
                        type="submit"
                      >
                        <strong>Aceptar</strong>
                      </Button>
                    </Box>
                  </form>
                </React.Fragment>
              )}

              
            </Box>
          </Paper>
        </Grid>

      </Grid>
    </div>
  );
}

export default ResetPassword;