import React, { useState, useContext, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import { Container, Paper, FormControl, FormLabel, RadioGroup, 
  FormControlLabel, Radio, Grid, Button, FormHelperText, Box } 
  from '@material-ui/core';
import { AuthContext } from '../../shared/context/auth-context';

const useStyles = makeStyles(theme => ({
  formControl: {
    width: '100%'
  },
  addressPaper: {
    padding: theme.spacing(2)
  },
  addressLabel: {
    marginBottom: theme.spacing(2)
  },
  buttonGrid: {
    marginTop: theme.spacing(3)
  },
  helperText: {
    marginTop: theme.spacing(3)
  },
  newCardButton: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(3)
  }
}));


const CardChange = props => {
  const classes = useStyles();
  const [cardNumber, setCardNumber] = useState(null);
  const [showError, setShowError] = useState(false);
  const [userCards, setUserCards] = useState(null);
  const auth = useContext(AuthContext);
  
  useEffect(() => {
    let isSubscribed = true;
  
    fetch(process.env.REACT_APP_API_URL+'/api/users/'+auth.userId+'/cards',{
      headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer '+auth.token }
    })
      .then(result => result.json())
      .then(data => (isSubscribed ? setUserCards(data) : null))
    
    return () => (isSubscribed = false);
  }, [auth.userId, auth.token])

  const handleChange = event => {
    event.persist();
    setCardNumber(event.target.value);
  };

  const handleNewCard = event => {
    event.preventDefault();
    global.window && (global.window.location.href = process.env.REACT_APP_API_URL+"/tbk/init-inscription/"+auth.userId+"/"+props.idClub);
    return null;
  }

  const handleSubmitCard = event => {
    event.preventDefault();
    if(!cardNumber){
      setShowError(true);
    }else{
      let data = {
        userId: auth.userId,
        idClub: props.idClub,
        cardNumber: cardNumber
      }
  
      fetch(process.env.REACT_APP_API_URL+"/api/subscriptions/change-card", {
        method: 'POST',
        body: JSON.stringify(data),
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer '+auth.token }
      })
      .then(res => res.json())
      .then(res => { 
        console.log(res);
        props.onUpdateCard();
      })
    }
  }

  return (
    <Container>
      <Button
        variant="contained"
        color="default"
        startIcon={<CreditCardIcon />}
        className={classes.newCardButton}
        fullWidth={true}
        onClick={handleNewCard}
      >
        <strong>Agregar una nueva tarjeta de credito</strong>
      </Button>
      {userCards && userCards['userCards'].length > 0 && (
        <React.Fragment>
          <FormControl component="fieldset" className={classes.formControl}  error={showError}>
            <FormLabel component="legend" className={classes.addressLabel}>Selecciona una tarjeta</FormLabel>
            <RadioGroup aria-label="card" name="cards" value={cardNumber} onChange={handleChange}>
              { userCards && userCards['userCards'].map(card => (
                <Box mb={2} key={card.cardNumber}>
                  <Paper elevation={3} className={classes.addressPaper}>
                    <FormControlLabel 
                      value={card.cardNumber} 
                      control={<Radio />} 
                      label={'Tarjeta de crédito terminada en: '+card.cardNumber} 
                    />
                  </Paper>
                </Box>
              ))}
            </RadioGroup>
            {showError && (
              <FormHelperText className={classes.helperText}>Selecciona una tarjeta, o bien, ingresa una nueva.</FormHelperText>
            )}
          </FormControl>
          <Grid container className={classes.buttonGrid} spacing={2} justify="flex-end">
            <Grid item xs={12} md={4}>
              <Button 
                variant="contained" 
                color="secondary" 
                fullWidth={true}
                onClick={handleSubmitCard}
              >
                <strong>Guardar</strong>
              </Button>
            </Grid>
          </Grid>
        </React.Fragment>
      )}

    </Container>
  )
}

export default CardChange;