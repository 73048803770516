import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { AuthContext } from '../../shared/context/auth-context';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails, Radio, FormControlLabel, Typography, Button, Box, Divider } 
  from '@material-ui/core';
import { Alert } from '@material-ui/lab';

import FacebookIcon from '@material-ui/icons/Facebook'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import jwt from 'jsonwebtoken';
import GoogleLogin from 'react-google-login';
import GroupIcon from '@material-ui/icons/Group';

import LoginForm from '../components/LoginForm';
import RegisterForm from '../components/RegisterForm';

import background from '../../assets/images/background-suscripcion-the-box.jpg';
import backgroundmobile from '../../assets/images/home/movil/background-suscripcion-the-box-movil.jpg';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    paddingTop: theme.spacing(4),
    minHeight: "600px",
    [theme.breakpoints.up('sm')]: {
      backgroundImage: `url(${background})`,
      backgroundSize: "100% 100%"
    },
    [theme.breakpoints.down('sm')]: {
      background: `url(${backgroundmobile}) no-repeat center center`,
    },
  },
  googleButton: {
    color: '#757575',
    backgroundColor: '#fff',
    '&:hover' : {
      opacity: '0.9'
    }
  },
  facebookButton: {
    color: '#fff',
    backgroundColor: '#3C5898',
    '&:hover' : {
      backgroundColor: '#29487D'
    }
  }
}));

const Auth = (props) => {
  const auth = useContext(AuthContext);
  let history = useHistory();
  const classes = useStyles();
  const [loginExpanded, setLoginExpanded] = useState(true);
  const [registerExpanded, setRegisterExpanded] = useState(false);
  const [error, setError] = useState({ show: false, message: '' })

  const togglePanelHandler = event => {
    event.preventDefault();
    setLoginExpanded(!loginExpanded);
    setRegisterExpanded(!registerExpanded);
  }

  const responseFaceebook = (response) => {
    if(response.status === "unknown"){
      setError({show: true, message: 'Ha ocurrido un error al conectarse con Facebook, por favor intente nuevamente.'})
    }else{
      if(!response.email){
        setError({show: true, message: 'La cuenta de Facebook no posee un email vinculado, por favor intente identificarse de forma manual.'})
      }else{
        let tokenLogin = jwt.sign(
          {
            facebookId: response.userID,
            email: response.email,
          },
          process.env.REACT_APP_JWT_LOGIN,
          { expiresIn: '1h'}
        );

        let data = {
          token: tokenLogin,
          first_name: response.first_name,
          last_name: response.last_name
        }
        
        fetch(process.env.REACT_APP_API_URL+"/api/users/oauth/facebook", {
          method: 'POST',
          body: JSON.stringify(data),
          headers: { 'Content-Type': 'application/json' }
        })
        .then(res => res.json())
        .then(res => { 
          if(res.userData){
            auth.login(res.userData, res.token)
            if(res.userData.hasOneSub){
              history.push("/panel_usuario");
            }else{
              history.push("/suscripcion");
            }
          }
        })
      }
    }
  }

  const responseGoogle = (response) => {
    if(response.error){
      setError({show:true, message: 'Proceso de identificación detenido por el usuario.'})
    }else{
      if(!response.profileObj.email){
        setError({show: true, message: 'Google no ha retornado un email de usuario, por favor intente identificarse de forma manual.'})
      }else{
        let data = {
          googleId: response.profileObj.googleId,
          email: response.profileObj.email,
          first_name: response.profileObj.givenName,
          last_name: response.profileObj.familyName || null,
          accessToken: response.accessToken,
          tokenId: response.tokenId
        }

        fetch(process.env.REACT_APP_API_URL+"/api/users/oauth/google", {
          method: 'POST',
          body: JSON.stringify(data),
          headers: { 'Content-Type': 'application/json' }
        })
        .then(res => res.json())
        .then(res => { 
          console.log(res);
          if(res.userData){
            auth.login(res.userData, res.token)
            if(res.userData.hasOneSub){
              history.push("/panel_usuario");
            }else{
              history.push("/suscripcion");
            }
          }
        })
      }
    }
  }

  const handleAlertClose = () => {
    setError({ show: false, message: '' })
  }

  return (
    <div className={classes.root}>
      { error.show && (
        <Box mb={3}>
          <Grid 
            container
            direction="row"
            justify="center"
            alignItems="center"
          >
            <Grid item xs={12} md={4} lg={4} xl={3}>
              <Alert severity="error" onClose={handleAlertClose}>
                { error.message }
              </Alert>
            </Grid>
          </Grid>
        </Box>
      )}

      <Grid
        container
        direction="row"
        justify="center"
        alignItems="center"
      >
        <Grid item xs={12} md={4} lg={4} xl={3}>
          <ExpansionPanel expanded={registerExpanded} onChange={togglePanelHandler}>
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              aria-label="Expand"
              aria-controls="additional-actions1-content"
              id="additional-actions1-header"
            >
              <FormControlLabel
                checked={registerExpanded}
                aria-label="Registro"
                onChange={togglePanelHandler}
                onFocus={event => event.stopPropagation()}
                control={<Radio />}
                label={
                  <div>
                    <Typography variant="body1">
                      ¿Eres nuevo?
                    </Typography>
                    <Typography variant="body1">
                      Crea una cuenta
                    </Typography>
                  </div>
                }
              />
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Grid container>
                <Grid item xs={12}>
                  <RegisterForm></RegisterForm>
                </Grid>
                <Grid item xs={12}>
                  <Box mt={2}>
                    <Divider></Divider>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Box mt={2} mb={2}>
                    <FacebookLogin 
                      appId={process.env.REACT_APP_ENV === 'production' ? 3197727746906045 : 382888183265577 }
                      fields="first_name,last_name,name,email"
                      callback={responseFaceebook}
                      redirectUri={process.env.REACT_APP_ENV === 'production' ? 'club.booz-cl/panel_usuario' : 'localhost/panel_usuario'}
                      disableMobileRedirect={true}
                      render={renderProps => (
                        <Button 
                          variant="contained" 
                          onClick={renderProps.onClick}
                          disabled={renderProps.disabled}
                          fullWidth={true} 
                          disableElevation 
                          startIcon={<FacebookIcon/>}
                          className={classes.facebookButton}
                        >
                          <strong>
                            Conectar con Facebook
                          </strong>
                        </Button>
                      )}
                    />
                  </Box>

                </Grid>
                <Grid item xs={12}>
                  <GoogleLogin 
                    clientId=
                    {process.env.REACT_APP_ENV === 'production' ? 
                      "397137783468-hia4sf2beb1p5taf5lkb8cppe4k092cc.apps.googleusercontent.com" : 
                      "294163774620-nb72ageo95lkrsur4vdp1id6kp0n36uu.apps.googleusercontent.com"
                    }
                    render={renderProps => (
                      <Button 
                        variant="contained" 
                        onClick={renderProps.onClick}
                        disabled={renderProps.disabled}
                        fullWidth={true} 
                        startIcon={<GroupIcon/>}
                        className={classes.googleButton}
                      >
                        <strong>
                          Conectar con Google
                        </strong>
                      </Button>
                    )}
                    buttonText="Google Login"
                    onSuccess={responseGoogle}
                    onFailure={responseGoogle}
                    cookiePolicy={'single_host_origin'}
                  />
                </Grid>
              </Grid>
            </ExpansionPanelDetails>
          </ExpansionPanel>
          <ExpansionPanel expanded={loginExpanded} onChange={togglePanelHandler}>
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              aria-label="Expand"
              aria-controls="additional-actions2-content"
              id="additional-actions2-header"
            >
              <FormControlLabel
                checked={loginExpanded}
                aria-label="Iniciar sesión"
                onChange={togglePanelHandler}
                onFocus={event => event.stopPropagation()}
                control={<Radio />}
                label={
                  <div>
                    <Typography variant="body1">
                      ¿Ya tienes cuenta?
                    </Typography>
                    <Typography variant="body1">
                      Inicia sesión
                    </Typography>
                  </div>
                }
              />
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Grid container>
                <Grid item xs={12}>
                  <LoginForm></LoginForm>
                </Grid>
                <Grid item xs={12}>
                  <Box mt={2}>
                    <Divider></Divider>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Box mt={2} mb={2}>
                    <FacebookLogin 
                      appId={process.env.REACT_APP_ENV === 'production' ? 3197727746906045 : 926907380778669}
                      fields="first_name,last_name,name,email"
                      callback={responseFaceebook}
                      redirectUri={process.env.REACT_APP_ENV === 'production' ? 'club.booz-cl/panel_usuario' : 'localhost/panel_usuario'}
                      disableMobileRedirect={true}
                      render={renderProps => (
                        <Button 
                          variant="contained" 
                          onClick={renderProps.onClick}
                          disabled={renderProps.disabled}
                          fullWidth={true} 
                          disableElevation 
                          startIcon={<FacebookIcon/>}
                          className={classes.facebookButton}
                        >
                          <strong>
                            Conectar con Facebook
                          </strong>
                        </Button>
                      )}
                    />
                  </Box>

                </Grid>
                <Grid item xs={12}>
                  <GoogleLogin 
                    clientId=
                    {process.env.REACT_APP_ENV === 'production' ? 
                      "397137783468-hia4sf2beb1p5taf5lkb8cppe4k092cc.apps.googleusercontent.com" : 
                      "294163774620-nb72ageo95lkrsur4vdp1id6kp0n36uu.apps.googleusercontent.com"
                    }
                    render={renderProps => (
                      <Button 
                        variant="contained" 
                        onClick={renderProps.onClick}
                        disabled={renderProps.disabled}
                        fullWidth={true} 
                        startIcon={<GroupIcon/>}
                        className={classes.googleButton}
                      >
                        <strong>
                          Conectar con Google
                        </strong>
                      </Button>
                    )}
                    buttonText="Google Login"
                    onSuccess={responseGoogle}
                    onFailure={responseGoogle}
                    cookiePolicy={'single_host_origin'}
                  />
                </Grid>
              </Grid>

            </ExpansionPanelDetails>
          </ExpansionPanel>
        </Grid>


      </Grid>
    </div>
  )
};

export default Auth;