import React, { useState, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import { Container, Paper, FormControl, FormLabel, RadioGroup, 
  FormControlLabel, Radio, Grid, Button, FormHelperText, Box } 
  from '@material-ui/core';
import { AuthContext } from '../../shared/context/auth-context';

const useStyles = makeStyles(theme => ({
  formControl: {
    width: '100%'
  },
  addressPaper: {
    padding: theme.spacing(2)
  },
  addressLabel: {
    marginBottom: theme.spacing(2)
  },
  buttonGrid: {
    marginTop: theme.spacing(3)
  },
  helperText: {
    marginTop: theme.spacing(3)
  },
  newCardButton: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(3)
  }
}));


const CardList = props => {
  const classes = useStyles();
  const [value, setValue] = useState(null);
  const [showError, setShowError] = useState(false);
  const auth = useContext(AuthContext);
  let storedAddressData = JSON.parse(localStorage.getItem('selectedAddress'));

  const handleNextStep = () => {
    props.onNextStep();
  }

  const handleChange = event => {
    setValue(event.target.value);
  };

  const handleNewCard = event => {
    event.preventDefault();
    global.window && (global.window.location.href = process.env.REACT_APP_API_URL+"/tbk/init-inscription/"+auth.userId+"/"+props.subId+"/"+storedAddressData.pos);
    return null;
  }

  const handleSubmitCard = event => {
    event.preventDefault();
    if(!value){
      setShowError(true);
    }else{
      let data = {
        userId: auth.userId,
        subId: props.subId,
        cardNumber: value,
        address: storedAddressData.address
      }
      console.log('data to send >', data);

      fetch(process.env.REACT_APP_API_URL+"/api/subscriptions/user/subscribe", {
        method: 'POST',
        body: JSON.stringify(data),
        headers: { 'Content-Type': 'application/json' }
      })
      .then(res => res.json())
      .then(res => { 
        console.log(res);
        let storedData = JSON.parse(localStorage.getItem('userData'));
        storedData.userData.hasOneSub = true;
        localStorage.setItem('userData', JSON.stringify(storedData));
        localStorage.removeItem('selectedAddress');
        handleNextStep();
      })
      
      // userId, subId, cardNumber, address 
      // /user/subscribe
    }
  }

  return (
    <Container>
      <Button
        variant="contained"
        color="default"
        startIcon={<CreditCardIcon />}
        className={classes.newCardButton}
        fullWidth={true}
        onClick={handleNewCard}
      >
        <strong>Agregar una nueva tarjeta de credito</strong>
      </Button>
      {props.userCards['userCards'] && props.userCards['userCards'].length > 0 && (
        <React.Fragment>
          <FormControl component="fieldset" className={classes.formControl}  error={showError}>
            <FormLabel component="legend" className={classes.addressLabel}>Selecciona una tarjeta</FormLabel>
            <RadioGroup aria-label="card" name="cards" value={value} onChange={handleChange}>
              { props.userCards['userCards'] && props.userCards['userCards'].map(card => (
                <Box key={card.cardNumber} mb={2}>
                  <Paper elevation={3} className={classes.addressPaper}>
                    <FormControlLabel 
                      value={card.cardNumber} 
                      control={<Radio />} 
                      label={'Tarjeta de crédito terminada en: '+card.cardNumber} 
                    />
                  </Paper>
                </Box>
              ))}
            </RadioGroup>
            {showError && (
              <FormHelperText className={classes.helperText}>Selecciona una tarjeta, o bien, ingresa una nueva.</FormHelperText>
            )}
          </FormControl>
          <Grid container className={classes.buttonGrid} spacing={2} justify="flex-end">
            <Grid item xs={12} md={4}>
              <Button 
                variant="contained" 
                color="secondary" 
                fullWidth={true}
                onClick={handleSubmitCard}
              >
                <strong>Terminar</strong>
              </Button>
            </Grid>
          </Grid>
        </React.Fragment>
      )}

    </Container>
  )
}

export default CardList;