import React, { useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Formik, Form, Field } from 'formik';
import { TextField } from 'formik-material-ui';
import { makeStyles, Button, LinearProgress, Grid } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';
import { AuthContext } from '../../shared/context/auth-context';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  formControl: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
    minWidth: 120,
  },
  formControlMonth: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
    minWidth: 174,
  },
  formControlYear: {
    marginBottom: theme.spacing(1),
    minWidth: 120,
  },
  textField: {
    marginBottom: theme.spacing(2)
  },
  alert: {
    marginBottom: theme.spacing(3)
  },
  error: {
    color: "#f44336"
  }
}));

const CustomizedSelectForFormik = ({ children, form, field }) => {
  const { name, value } = field;
  const { setFieldValue } = form;

  return (
    <Select
      name={name}
      value={value}
      onChange={e => {
        setFieldValue(name, e.target.value);
      }}
    >
      {children}
    </Select>
  );
};

const RegisterForm = props => {
  const classes = useStyles();
  const [showError, setShowError] = useState(false);
  const [errorMsj, setErrorMsj] = useState('');
  const [validateBirthDate, setValidateBirthDate] = useState(false);
  const auth = useContext(AuthContext);
  let history = useHistory();

  const buildOptionsDays = () => {
    let optionsArrayDays = [];

    for (var i = 1; i <= 31; i++){
      optionsArrayDays.push(<MenuItem value={i}>{i}</MenuItem>);
    }

    return optionsArrayDays;
  }

  const buildOptionsYears = () => {
    let optionsArrayYears = [];
    let date = new Date();

    for (var i = (date.getFullYear() - 17); i > 1900; i--){
      optionsArrayYears.push(<MenuItem value={i}>{i}</MenuItem>);
    }

    return optionsArrayYears;
  }

  const handleAlertClose = (event) => {
    event.preventDefault();
    setShowError(false);
    setErrorMsj('');
  }

  return (
    <Grid container>
      <Grid item xs={12}>
      {showError && (
        <Alert severity="error" className={classes.alert} onClose={handleAlertClose}>
          { errorMsj }
        </Alert>
      )}
      <Formik
      validateOnBlur
      initialValues={{ email: '', name: '', lastName: '', password: '', dayBirthDate: '', monthBirthDate: '', yearBirthDate: '' }}
      validate={values => {
        const errors = {};
        if(!values.email){
          errors.email = 'Introduce tu dirección de email';
        }else if( !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email) ){
          errors.email = 'La dirección de-mail no es válida';
        }
        if(!values.name){
          errors.name = 'Introduce tu nombre';
        }
        if(!values.lastName){
          errors.lastName = 'Introduce tu apellido';
        }
        if(!values.dayBirthDate){
          errors.dayBirthDate = 'Ingresa el día';
          setValidateBirthDate(true);
        }
        if(!values.monthBirthDate){
          errors.monthBirthDate = 'Ingresa el mes';
          setValidateBirthDate(true);
        }
        if(!values.yearBirthDate){
          errors.yearBirthDate = 'Ingresa el año';
          setValidateBirthDate(true);
        }
        if(values.dayBirthDate && values.monthBirthDate && values.yearBirthDate){
          let diffYears = moment().diff(moment([values.yearBirthDate, (parseInt(values.monthBirthDate)- 1),  values.dayBirthDate]), 'years');
          if(diffYears < 18){
            setValidateBirthDate(true);
          }else{
            setValidateBirthDate(false);
          }
        }
        if(!values.password){
          errors.password = 'Introduce tu contraseña';
        }else if(values.password.length < 6){
          errors.password = 'La contraseña debe tener al menos seis carácteres';
        }else if( !/^[0-9a-zA-Z]+$/.test(values.password) ){
          errors.password = 'La contraseña solo puede tener números y letras';
        }
        return errors;
      }}
      onSubmit={(values, { setSubmitting }) => {
        const sendRequest = async () => {
          if(validateBirthDate){
            setErrorMsj('Debes ser mayor de edad.');
            setShowError(true);
          }else{
            const data = {
              name: values.name,
              lastName: values.lastName,
              email: values.email,
              password: values.password,
              dayBirthDate: values.dayBirthDate,
              monthBirthDate: values.monthBirthDate,
              yearBirthDate: values.yearBirthDate
            }
            const response = await fetch(process.env.REACT_APP_API_URL+'/api/users/register', {
              method: 'POST',
              body: JSON.stringify(data),
              headers: { 'Content-Type': 'application/json' }
            });
            console.log('api response code >', response.status);
            const responseData = await (await response).json();
            
            if(response.status !== 200){
              if(response.status === 500){
                setErrorMsj('Ocurrió un error inesperado, intenta nuevamente.');
                setShowError(true);
              }else{
                setErrorMsj(responseData.message);
                setShowError(true);
              }
            }else{
              console.log('api response >', responseData);
              auth.login(responseData.userData, responseData.token)
              history.push("/suscripcion")
            }     
          }
        }
        sendRequest();
        setSubmitting(false);
      }}
    >
      {({ submitForm, isSubmitting }) => (
        <Form>
          <Field 
            component={TextField}
            id="name-error-helper-text"
            className={classes.textField}
            label="Nombre"
            variant="outlined"
            fullWidth={true}
            size="small"
            name="name"
          />
          <Field 
            component={TextField}
            id="lastname-error-helper-text"
            className={classes.textField}
            label="Apellido"
            variant="outlined"
            fullWidth={true}
            size="small"
            name="lastName"
          />
          <Field 
            component={TextField}
            id="email-error-helper-text"
            className={classes.textField}
            label="Ingresa tu correo"
            variant="outlined"
            fullWidth={true}
            size="small"
            name="email"
          />
          <Field 
            component={TextField}
            id="password-error-helper-text"
            className={classes.textField}
            label="Contraseña"
            variant="outlined"
            fullWidth={true}
            size="small"
            name="password"
            type="password"
          />
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel id="day-bithDate-label">Día</InputLabel>
            <Field
              labelId="day-bithDate-label"
              id="day-bithDate"
              name="dayBirthDate"
              variant="outlined"
              component={CustomizedSelectForFormik}
            >
              {buildOptionsDays()}
            </Field>
          </FormControl>
          <FormControl variant="outlined" className={classes.formControlMonth}>
            <InputLabel id="day-bithDate-label">Mes</InputLabel>
            <Field
              labelId="month-bithDate-label"
              id="month-bithDate"
              name="monthBirthDate"
              variant="outlined"
              component={CustomizedSelectForFormik}
            >
              <MenuItem value="1">Enero</MenuItem>
              <MenuItem value="2">Febrero</MenuItem>
              <MenuItem value="3">Marzo</MenuItem>
              <MenuItem value="4">Abril</MenuItem>
              <MenuItem value="5">Mayo</MenuItem>
              <MenuItem value="6">Junio</MenuItem>
              <MenuItem value="7">Julio</MenuItem>
              <MenuItem value="8">Agosto</MenuItem>
              <MenuItem value="9">Septiembre</MenuItem>
              <MenuItem value="10">Octubre</MenuItem>
              <MenuItem value="11">Noviembre</MenuItem>
              <MenuItem value="12">Diciembre</MenuItem>
            </Field>
          </FormControl>
          <FormControl variant="outlined" className={classes.formControlYear}>
            <InputLabel id="year-bithDate-label">Año</InputLabel>
            <Field
              labelId="year-bithDate-label"
              id="year-bithDate"
              name="yearBirthDate"
              variant="outlined"
              component={CustomizedSelectForFormik}
            >
              {buildOptionsYears()}
            </Field>
          </FormControl>
          <Typography variant="subtitle2" className={(validateBirthDate) ? classes.error : ''} gutterBottom>
            Debes ser mayor de 18 años.
          </Typography>
          {isSubmitting && <LinearProgress />}
          <br />
          <Button 
            fullWidth={true} 
            variant="contained" 
            color="primary"
            disabled={isSubmitting}
            onClick={submitForm}
          >
            <strong>Continuar</strong>
          </Button>
        </Form>
      )}
    </Formik>
      </Grid>
    </Grid>

  )
}

export default RegisterForm;
