import { createContext } from 'react';

export const AuthContext = createContext({
    isLoggedIn: false,
    hasOneSub: false,
    userId: null,
    userEmail: null,
    userFirstName: null,
    token: null, 
    login: () => {}, 
    logout: () => {}
});