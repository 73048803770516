import React, { useEffect, useState } from 'react';
import { Grid, Paper, Box, Typography, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  }
}));

const ErrorInscription = props => {
  const classes = useStyles();
  let history = useHistory();
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if(!loaded){
      var storedData = JSON.parse(localStorage.getItem('userData'));
      if(storedData && storedData.token && new Date(storedData.expiration) > new Date() ) {
        storedData.userData.hasOneSub = true;
        localStorage.setItem('userData', JSON.stringify(storedData));
        setLoaded(true);
      }
    }

  }, [loaded])

  const handleBackHomeClick = () => {
    history.push("/");
  }

  return (
    <div className={classes.root}>
      <Grid 
      container
      direction="row"
      justify="center"
      alignItems="center"
      >
        <Grid item xs={12} md={4}>
          
          <Paper elevation={3}>
            <Box p={4}>
            <Grid item xs={12} md={12}>
                  <Typography variant="h3" align="center" className={classes.instructions} gutterBottom>
                    ¡Gracias por inscribirte al Club!
                  </Typography>
                  <Typography variant="h5" align="center" className={classes.instructions}>
                    Te contactaremos cuando tu caja esté en camino
                  </Typography>
                </Grid>
                <Grid item xs={12} md={12}>
                  <Box mt={3}>
                    <Button
                      color="secondary"
                      variant="contained"
                      fullWidth={true}
                      onClick={handleBackHomeClick}
                    >
                      <strong>Volver al home</strong>
                    </Button>
                  </Box>
                </Grid>

            </Box>
          </Paper>
        </Grid>

      </Grid>
    </div>
  );
}

export default ErrorInscription;