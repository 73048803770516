import React, { useState } from 'react';
import { Button } from '@material-ui/core';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Box } from '@material-ui/core';
import BoozAlert from '../../shared/components/booz-alert';

const PasswordResetDialog = props => {
  const [open, setOpen] = useState(false);
  const [showResend, setShowResend] = useState(false);
  const [error, setError] = useState({ show: false, msg: '' });

  const handleClose = () => {
    setOpen(false);
    setShowResend(false);
  }

  const handleSendReset = () => {
    //logica de resetear password
    let data = {
      email: props.userEmail
    }

    fetch(process.env.REACT_APP_API_URL+"/api/users/send-reset-password", {
      method: 'POST',
      body: JSON.stringify(data),
      headers: { 'Content-Type': 'application/json' }
    })
    .then(res => res.json())
    .then(res => { 
      if(res.success && res.success === true){
        setShowResend(true);
      }else if(res.success === false){
        setError({ show: true, msg: res.msg });
      }
    })
  }
  
  const handleResend = () => {
    handleSendReset();
  }

  const handleAlertClose = (event) => {
    event.preventDefault();
    setError({ show: false, msg: '' });
  } 

  return (
    <React.Fragment>
      {props.userEmail && (
        <Button variant="contained" fullWidth={true} onClick={() => setOpen(true)}>
          <strong>Solicitar cambio de clave</strong>
        </Button>
      )}

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="password-reset-dialog-title"
        aria-describedby="password-reset-dialog-description"
        maxWidth="xs"
      >
        {!showResend && (
          <React.Fragment>
            <DialogTitle id="password-reset-dialog-title">{"¿Está seguro que quiere cambiar su clave?"}</DialogTitle>
            <DialogContent>
              <Box mb={3} display={error.show ? 'block' : 'none'}>
                <BoozAlert 
                  show={error.show}
                  message={error.msg}
                  severity={'error'}
                  onClose={handleAlertClose}
                />
              </Box>
              <DialogContentText id="password-reset-dialog-description">
                {"Le enviarlemos un enlace para realizar el cambio al correo "+props.userEmail}
              </DialogContentText>
              <DialogActions>
                <Button onClick={handleClose} variant="contained" fullWidth={true}>
                  Volver
                </Button>
                <Button onClick={handleSendReset} variant="contained" color="primary" fullWidth={true} autoFocus>
                  <strong>Enviar</strong>
                </Button>
              </DialogActions>
            </DialogContent>
          </React.Fragment>
        )}

        {showResend && (
          <React.Fragment>
            <DialogTitle id="password-reset-dialog-title">{"¡Email enviado!"}</DialogTitle>
            <DialogContent>
              <DialogContentText id="password-reset-dialog-description">
                {"Para recuperar tu contraseña sigue las instrucciones enviadas al email "+props.userEmail}
              </DialogContentText>
              <DialogActions>
                <Button onClick={handleClose} variant="contained" fullWidth={true}>
                  Volver
                </Button>
                <Button onClick={handleResend} variant="contained" color="primary" fullWidth={true} autoFocus>
                  <strong>Reenviar email</strong>
                </Button>
              </DialogActions>
            </DialogContent>
          </React.Fragment>
        )}

      </Dialog>
    </React.Fragment>
  )
}

export default PasswordResetDialog;