import React, { useState } from 'react';
import { Formik, Form, Field } from 'formik';
import { TextField } from 'formik-material-ui';
import { makeStyles, Button, Box, LinearProgress } from '@material-ui/core';
import BoozAlert from '../../shared/components/booz-alert';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  textField: {
    marginBottom: theme.spacing(2)
  },
}));

const EmailCheck = props => {
  const emailCheckHandler = (userEmail) => {
    props.onEmailChecked(userEmail);
  }
  const classes = useStyles();
  const [error, setError] = useState({ show: false, msg: '' });
  
  const handleAlertClose = (event) => {
    event.preventDefault();
    setError({ show: false, msg: '' });
  } 

  return (
    <React.Fragment>
      <Box mb={3} display={error.show ? 'block' : 'none'}>
        <BoozAlert 
          show={error.show}
          message={error.msg}
          severity={'error'}
          onClose={handleAlertClose}
        />
      </Box>
      <Formik
        validateOnBlur
        initialValues={{ email: '' }}
        validate={values => {
          const errors = {};
          if(!values.email){
            errors.email = 'Introduce tu dirección de email';
          }else if( !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email) ){
            errors.email = 'La dirección de-mail no es válida';
          }
          return errors;
        }}
        onSubmit={(values, { setSubmitting }) => {
          const sendRequest = async () => {
            const response = await fetch(process.env.REACT_APP_API_URL+'/api/users/'+values.email);
            switch(response.status){
              case 200:
                emailCheckHandler(values.email);
                break;
              case 404:
                setError({ show: true, msg: 'El correo '+values.email+' no tiene cuenta registrada en BOOZ. Por favor intente nuevamente.' });
                break;
              case 500:
                setError({ show: true, msg: 'Ocurrió un error al procesar tus datos, por favor intente nuvamente.' });
                break;
              default:
                setError({ show: true, msg: 'Ocurrió un error inesperado, por favor intente nuvamente.' });
                break;
            }
          }
          sendRequest();
          setSubmitting(false);
        }}
      >
        {({ submitForm, isSubmitting }) => (
          <Form>
            <Field 
              component={TextField}
              id="outlined-error-helper-text"
              className={classes.textField}
              label="Ingresa tu correo"
              variant="outlined"
              fullWidth={true}
              size="small"
              name="email"
            />
            {isSubmitting && <LinearProgress />}
            <br />
            <Button 
              fullWidth={true} 
              variant="contained" 
              color="primary"
              disabled={isSubmitting}
              onClick={submitForm}
            >
              <strong>Continuar</strong>
            </Button>
          </Form>
        )}
      </Formik>
    </React.Fragment>

  )
}

export default EmailCheck;
