import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Drawer from '@material-ui/core/Drawer';
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';
import { Link as RouterLink } from 'react-router-dom';
import { AuthContext } from  '../../context/auth-context'


import logo from '../../../assets/images/navbar/the-box-logo.png';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  subscribeButton: {
    marginLeft: theme.spacing(2)
  },
  list: {
    width: 250
  },
  paperAnchorLeft: {
    background: '#232323',
    color: '#ffffff'
  },
  listItemIcon: {
    color: '#ffffff'
  }
}));

const MobileNavbar = () => {
  const classes = useStyles();
  const auth = useContext(AuthContext);
  const [state, setState] = React.useState({
    open: false
  })

  const toggleDrawer = (open) => event => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ open : open });
  }

  const menuList = () => (
    <div
      className={classes.list}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <List>
        {auth.token && (
          <React.Fragment>
            <ListItem button key="Home" component={RouterLink} to="/">
              <ListItemText primary={"Bienvenido, "+auth.userFirstName}></ListItemText>
            </ListItem>
            <Divider />
          </React.Fragment>
        )}

        <ListItem button key="Home" component={RouterLink} to="/">
          <ListItemIcon classes={{ root: classes.listItemIcon }}><PersonOutlineOutlinedIcon/></ListItemIcon>
          <ListItemText primary="Home"></ListItemText>
        </ListItem>

        {/* links available for logged users */}
        {auth.token && (
          <React.Fragment>
            {auth.hasOneSub && (
              <ListItem button key="Mis Subscripciones" component={RouterLink} to="/panel_usuario">
                <ListItemIcon classes={{ root: classes.listItemIcon }}><PersonOutlineOutlinedIcon/></ListItemIcon>
                <ListItemText primary="Mis Subscripciones"></ListItemText>
              </ListItem>
            )}  
            <ListItem button key="Cerrar sesión" component={RouterLink} to="/logout">
              <ListItemIcon classes={{ root: classes.listItemIcon }}><PersonOutlineOutlinedIcon/></ListItemIcon>
              <ListItemText primary="Cerrar sesión"></ListItemText>
            </ListItem>
          </React.Fragment>
        )}

        {/* links available for guest only */}
        {!auth.token && (
          <ListItem button key="Inicia sesión" component={RouterLink} to="/auth">
            <ListItemIcon classes={{ root: classes.listItemIcon }}><PersonOutlineOutlinedIcon/></ListItemIcon>
            <ListItemText primary="Inicia sesión"></ListItemText>
          </ListItem>
        )}
      </List>
    </div>
  );

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Toolbar>

          <Drawer classes={{ paperAnchorLeft: classes.paperAnchorLeft }} open={state.open} onClose={toggleDrawer(false)}>
            { menuList() }
          </Drawer>

          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="center"
            spacing={2}
          >
            <Grid item xs={1}>
              <IconButton onClick={toggleDrawer(true)} edge="start" color="inherit" aria-label="menu">
                <MenuIcon />
              </IconButton>
            </Grid>
            
            <Grid item xs={7}>
              <Box display="flex" justifyContent="center">
                <Link component={ RouterLink } underline="none" to="/">
                  <img src={logo} alt="Booz The Box" height="32" width="122" />
                </Link>
              </Box>
            </Grid>

            <Grid item xs={4}>
              <Box display="flex" justifyContent="flex-end">
                {!auth.hasOneSub && (
                  <Button component={RouterLink} to={ auth.token ? '/suscripcion' : '/auth' } color="secondary" variant="contained" size="small" >
                    <strong className={classes.link}>Suscribete</strong>
                  </Button>
                )}
              </Box>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </div>
  );
}

export default MobileNavbar;