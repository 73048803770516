import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Grid, Typography, Link, List, ListItem, ListItemText, Divider } from '@material-ui/core';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import TwitterIcon from '@material-ui/icons/Twitter';

const useStyles = makeStyles(theme => ({
  main: {
    flexGrow: 1,
    color: '#fff'
  },
  footerTop: {
    backgroundColor: "#555555",
    backgroundClip: "padding",
    paddingTop: "1em",
    paddingRight: ".5em",
    paddingBottom: "1em",
    paddingLeft: ".5em"
  },
  topLink: {
    letterSpacing: "2px"
  },
  topLinkIcons: {
    marginRight: ".5em",
    fontSize: "3em"
  },
  footerBottom: {
    backgroundColor: "#333333",
    backgroundClip: "padding",
    paddingTop: "1em",
    paddingRight: "2em",
    paddingBottom: "1em",
    paddingLeft: "2em"
  }
}));

function ListItemLink(props) {
  return <ListItem button component="a" {...props} />;
}

const Footer = () => {
  const classes = useStyles()
  
  return (
    <footer className={classes.main}>
      <Grid 
        container
        direction="row"
        justify="center"
        alignItems="center"
        className={classes.footerTop}
      >
        <Grid item xs={12}>
          {/* <Typography variant="h6" className={classes.title}>
            Text only
          </Typography> */}
          <List dense={true}>
            <ListItemLink href="https://www.booz.cl/nuestra-empresa" target="_blank">
              <ListItemText
                className={classes.topLink}
                primary="NUESTRA EMPRESA"
              />
            </ListItemLink>
            <ListItemLink href="https://www.booz.cl/terminos-y-condiciones" target="_blank">
              <ListItemText
                className={classes.topLink}
                primary="TÉRMINOS Y CONDICIONES"
              />
            </ListItemLink>
            <ListItemLink href="https://www.booz.cl/privacidad" target="_blank">
              <ListItemText
                className={classes.topLink}
                primary="POLÍTICAS DE PRIVACIDAD"
              />
            </ListItemLink>
            <ListItemLink href="https://www.booz.cl/devoluciones" target="_blank">
              <ListItemText
                className={classes.topLink}
                primary="CAMBIOS, DEVOLUCIONES Y ANULACIONES"
              />
            </ListItemLink>
          </List>
        </Grid>

        <Grid item xs={12}>
          <Divider variant="middle" />
        </Grid>
        
        <Grid item xs={12}>
          <Box m={2}>
            <Typography component="div">
              <Box mb={1} textAlign="left" fontSize="body1.fontSize" fontWeight="fontWeightBold" letterSpacing={3}>
                HORARIOS
              </Box>
              <Box mb={1} textAlign="left" fontSize="body1.fontSize" letterSpacing={2}>
                <strong>Atención al Cliente </strong>
              </Box>
              <Box textAlign="left" fontSize="body2.fontSize" letterSpacing={2}>
                Lunes a Jueves: 11:00 a 20:00
              </Box>
              <Box textAlign="left" fontSize="body2.fontSize" letterSpacing={2}>
                Viernes: 11:00 a 16:00
              </Box>
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Divider variant="middle" />
        </Grid>

        <Grid item xs={12}>
          <Box m={2}>
            <Typography component="div">
              <Box mb={1} textAlign="left" fontSize="body1.fontSize" fontWeight="fontWeightBold" letterSpacing={3}>
                SÍGUENOS EN
              </Box>
            </Typography>
            <Typography>
              <Link href="https://www.facebook.com/boozcl" target="_blank" className={classes.topLinkIcons}>
                <FacebookIcon color="secondary" fontSize="inherit"></FacebookIcon>
              </Link>
              <Link href="https://www.instagram.com/boozcl" target="_blank" className={classes.topLinkIcons}>
                <InstagramIcon color="secondary" fontSize="inherit"></InstagramIcon>
              </Link>
              <Link href="https://www.twitter.com/boozcl" target="_blank" className={classes.topLinkIcons}>
                <TwitterIcon color="secondary" fontSize="inherit"></TwitterIcon>
              </Link>
            </Typography>
          </Box>

        </Grid>
      </Grid>
      <Grid 
        container
        direction="row"
        justify="center"
        alignItems="center"
        className={classes.footerBottom}
      >
        <Grid item md={12} xs={12}>

          <Typography component="div">
            <Box mb={1} textAlign="center" fontWeight="fontWeightBold" fontSize="h6.fontSize">
              &copy; {(new Date().getFullYear())} - Booz.cl
            </Box>
          </Typography>
        </Grid>

        <Grid item md={12} xs={12}>
          <Typography component="div">
            <Box textAlign="center" fontWeight="fontWeightBold" fontSize="body2.fontSize">
              Vive una nueva experiencia cada mes con vinos únicos y exclusivos.
            </Box>
          </Typography>
        </Grid>

      </Grid>
    </footer>
  )
}

export default Footer;