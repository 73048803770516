import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Formik, Form, Field } from 'formik';
import { TextField, Select, RadioGroup } from 'formik-material-ui';
import { Grid, InputLabel, FormControl, MenuItem, FormHelperText, Radio, FormControlLabel, FormLabel, Button, LinearProgress} from '@material-ui/core';
import MaskedInput from 'react-text-mask';
import { AuthContext } from '../../shared/context/auth-context';


function TextMaskCustom(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={['+', '5', '6', /[2|9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/]}
      placeholder={'+56'}
      guide={false}
      showMask
    />
  );
}

TextMaskCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
};

const useStyles = makeStyles(theme => ({
  formControl: {
    width: '100%'
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  inputGrid: {
    padding: theme.spacing(2),
  },
  buttonGrid: {
    marginTop: theme.spacing(3)
  }
}));

const AddressForm = props => {
  const classes = useStyles();
  const [communes, setCommunes] = useState(null);
  // const [communeError, setCommuneError] = useState(false);
  // const [communeErrorMsg, setCommuneErrorMsg] = useState(null);
  const [error, setError] = useState({ show: false, msg: '' });

  const auth = useContext(AuthContext);

  const handleNextStep = () => {
    props.onNextStep();
  }

  useEffect(() => {
    let isSubscribed = true;

    fetch(process.env.REACT_APP_API_URL+'/api/general/regions')
      .then(result => result.json())
      .then(data => (isSubscribed ? setCommunes(data[0].communes) : null))
    
    return () => (isSubscribed = false);
  }, [])

  return (
    <Formik
      validateOnBlur
      initialValues={{ phone: '', houseOrOffice: 'Casa', region: 'RM', commune: '', addressStreet: '', addressNumber: '', addressAdditional: ''}}
      validate={values => {
        const errors = {};
        if(!values.phone){
          errors.phone = 'Ingresa tu teléfono';
        }else if(!/^\D([+56][2-9])(\d{9})*$/i.test(values.phone)){
          errors.phone = 'Ingrese un número de 9 dígitos';
        }else if(values.phone === '+56'){
          errors.phone = 'Ingresa tu teléfono';
        }
        if(!values.houseOrOffice){
          errors.houseOrOffice = 'Selecciona tu tipo de dirección'
        }
        if(!values.region){
          errors.region = 'Selecciona una región'
        }
        if(!values.commune){
          errors.commune = 'Selecciona una comuna'
          // setCommuneError(true);
          // setCommuneErrorMsg(errors.commune);
          setError({show: true, msg: errors.commune})
        }else if(values.commune){
          // setCommuneError(false);
          // setCommuneErrorMsg(null);
          setError({show: false, msg: ''})

        }
        if(!values.addressStreet){
          errors.addressStreet = 'Ingresa tu dirección'
        }
        if(!values.addressNumber){
          errors.addressNumber = 'Ingresa el número de tu dirección'
        }
        return errors;
      }}
      onSubmit={(values, { setSubmitting }) => {
        let data = {
          userId: auth.userId,
          region: values.region,
          communeName: values.commune,
          houseOrOffice: values.houseOrOffice,
          addressStreet: values.addressStreet,
          addressNumber: values.addressNumber,
          addressAditional: values.addressAdditional,
          phone: values.phone
        }
        fetch(process.env.REACT_APP_API_URL+"/api/users/address", {
          method: 'POST',
          body: JSON.stringify(data),
          headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer '+auth.token }
        })
        .then(res => res.json())
        .then(res => { 
          const selectedAddress = res.address;
          if(!res.success){
            // alert("Comuna no permitida en Club de vinos.")
            setError({show: true, msg: 'The BOX aún no está disponible en la comuna seleccionada'});
            return;
          }
          if(props.location && props.location === "user_panel"){
            let changeAddressData = {
              userId: auth.userId,
              idClub: props.idClub,
              address: selectedAddress
            }

            fetch(process.env.REACT_APP_API_URL+"/api/subscriptions/change-address", {
              method: 'POST',
              body: JSON.stringify(changeAddressData),
              headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer '+auth.token }
            })
            .then(changeRes => changeRes.json())
            .then(changeRes => {
              props.onUpdateAddress();
            })
          }else if(props.location === "subscription"){
            const storedData = JSON.parse(localStorage.getItem('selectedAddress'));
            if(!storedData && selectedAddress){
              localStorage.setItem('selectedAddress', JSON.stringify({
                pos: res.posAddress,
                address: selectedAddress
              }));
            }else if(selectedAddress){
              localStorage.removeItem('selectedAddress');
              localStorage.setItem('selectedAddress', JSON.stringify({
                pos: res.posAddress,
                address: selectedAddress
              }));
            }
            handleNextStep();
          }

        })
        setSubmitting(false);
      }}
    >
    {({ submitForm, isSubmitting }) => (
      <Form>
        <Grid container>
          <Grid item xs={12} lg={6} className={classes.inputGrid}>
            <Field
              component={TextField}
              id="phone-input"
              label="Teléfono"
              fullWidth={true}
              name="phone"
              value='+56'
              className={classes.textField}
              // onChange={handleChange('textmask')}
              InputProps={{
                inputComponent: TextMaskCustom,
              }}
            >
            </Field>
          </Grid>

          <Grid item xs={12} md={6} className={classes.inputGrid}>
            <FormLabel component="legend">Tipo de Dirección</FormLabel>
            <Field component={RadioGroup} aria-label="casa u oficina" name="houseOrOffice" row>
              <FormControlLabel
                value="Casa"
                control={<Radio />}
                label="Casa"
                labelPlacement="end"
              ></FormControlLabel>
              <FormControlLabel
                value="Oficina"
                control={<Radio />}
                label="Oficina"
                labelPlacement="end"
              ></FormControlLabel>
            </Field>
          </Grid>
        
          <Grid item xs={12} md={6} className={classes.inputGrid}>
            <FormControl className={classes.formControl} disabled>
              <InputLabel shrink id="region-select-label" htmlFor="region-select">Región</InputLabel>
              <Field
                component={Select}
                labelId="region-select-label"
                name="region"
                inputProps={{
                  id: 'region-select',
                  value: 'RM'
                }}
              >
                <MenuItem value="RM">Región Metropolitana</MenuItem>
              </Field>
              <FormHelperText>Por el momento solo disponemos del servicio en la Región Metropolitana</FormHelperText>
            </FormControl>
          </Grid>

          <Grid item xs={12} md={6} className={classes.inputGrid}>
            <FormControl className={classes.formControl} error={error.show}>
              <InputLabel shrink id="commune-select-label" htmlFor="commune-select">Comuna</InputLabel>
              <Field
                component={Select}
                labelId="commune-select-label"
                name="commune"
                inputProps={{
                  id: 'commune-select',
                }}
              >
                { communes && communes.map(commune => (
                  <MenuItem key={commune} value={commune}>{commune}</MenuItem>
                ))}
              </Field>
              <FormHelperText>{error.msg}</FormHelperText>
            </FormControl>
          </Grid>

          <Grid item xs={12} md={8} className={classes.inputGrid}>
            <Field
              component={TextField}
              id="address-street-input"
              label="Dirección"
              fullWidth={true}
              name="addressStreet"
              className={classes.textField}
            >
            </Field>
          </Grid>

          <Grid item xs={12} md={4} className={classes.inputGrid}>
            <Field
              component={TextField}
              id="address-street-number-input"
              label="Número calle"
              fullWidth={true}
              name="addressNumber"
              className={classes.textField}
            >
            </Field>
          </Grid>

          <Grid item xs={12} md={4} className={classes.inputGrid}>
            <Field
              component={TextField}
              id="address-additional-input"
              label="Nº oficina o depto"
              fullWidth={true}
              name="addressAdditional"
              className={classes.textField}
            >
            </Field>
          </Grid>
          {isSubmitting && <LinearProgress />}
          <Grid container className={classes.buttonGrid} direction="row" justify="flex-end" alignItems="center">

            <Grid item xs={12} md={4}>
              <Button 
                variant="contained" 
                color="secondary" 
                fullWidth={true}
                disabled={isSubmitting} 
                onClick={submitForm}
              >
                <strong>Continuar</strong>
              </Button>
            </Grid>
          </Grid>
        
        </Grid>
      </Form>
    )}
    </Formik>
  )
}

export default AddressForm;